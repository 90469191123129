import React, { useState } from 'react';
import clsx from 'clsx';
import { ArrowButtonIcon } from 'assets/svg/ArrowButton';

type FAQ = {
  faqItem: {
    id: string;
    label: string;
    texts: {
      html: string;
      css: string;
    }[];
  };
};

export const FAQComponent = ({ faqItem }: FAQ) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={faqItem.id} className="py-1 px-4 md:px-8">
      <div
        onClick={toggleAccordion}
        className={clsx(
          'flex justify-between items-center px-4 bg-brand-purple-card w-full h-[45px] rounded-lg cursor-pointer',
          'bg-brand-purple-card text-sm md:text-base md:font-bold  font-semibold',
        )}
      >
        {faqItem.label}
        <ArrowButtonIcon className={isOpen ? 'fill-brand-purple-gray' : 'rotate-180 fill-brand-black'} />
      </div>
      {isOpen && (
        <div className="py-4 md:p-4 max-w-[760px]">
          {faqItem.texts?.map((text) => (
            <div key={text.html} className="text-sm/relaxed mb-6 break-words">
              <style dangerouslySetInnerHTML={{ __html: text.css }} />
              <div
                dangerouslySetInnerHTML={{ __html: text.html }}
                className={'[&_ul]:list-disc [&_ul]:list-inside [&_ol]:list-decimal [&_ol]:list-inside'}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
