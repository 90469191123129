import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';
import { TrashIcon } from 'assets/svg/Trash';

export const NotificationMenuItem = () => {
  return (
    <div className="relative rounded-md py-3 px-4 mb-3 bg-brand-purple-card">
      <div className="flex flex-col">
        <span className="text-xs text-brand-black font-black">Alert</span>
        <HorizontalDashedDelimiter className="flex flex-1 py-1" strokeDasharray={'0.1 8'} strokeLinecap="round" />
        <div className="flex flex-row justify-start items-center">
          <div className="rounded-md h-3 w-3 mr-3 bg-brand-red-main" />
          <span className="text-xs text-brand-black">
            Price for <span className="font-black">Mango</span> became lower than{' '}
            <span className="font-black">21.30</span>
          </span>
        </div>
      </div>
      <button className="absolute -top-2 -right-2 text-brand-purple-gray">
        <TrashIcon size={24} />
      </button>
    </div>
  );
};
