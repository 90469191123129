import { Link } from 'react-router-dom';
import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';
import { useDataValues } from 'providers/DataValues';
import { useModals } from 'providers/Modals';
import { KEYS, logEvent } from 'utils/analytics';

type HamburgerMenuProps = {
  onClick?: () => void;
};

const HamburgerMenu = ({ onClick }: HamburgerMenuProps) => {
  const { profile } = useDataValues();
  const { open: openModal } = useModals();

  return (
    <div className="max-h-[280px] w-full flex flex-col gap-2 text-brand-black p-2">
      <Link onClick={onClick} to={'/faq'}>
        FAQ
      </Link>
      <div />
      <Link
        onClick={() => {
          onClick?.();
          logEvent(KEYS.open_tutorials);
        }}
        to={'/tutorials'}
      >
        Tutorials
      </Link>
      <div />
      <HorizontalDashedDelimiter className="flex flex-1" strokeDasharray={'0.1 8'} strokeLinecap="round" />
      <Link
        to={'/privacy'}
        onClick={() => {
          logEvent(KEYS.privacy_policy);
        }}
      >
        Privacy Policy
      </Link>
      <Link
        to={'/terms'}
        onClick={() => {
          logEvent(KEYS.terms_conditions);
        }}
      >
        Terms & Conditions
      </Link>
      <button
        className={'text-left'}
        onClick={() => {
          onClick?.();
          openModal('report-issue', { email: profile.email || '' });
        }}
      >
        Report an issue
      </button>
      <HorizontalDashedDelimiter className="flex flex-1" strokeDasharray={'0.1 8'} strokeLinecap="round" />{' '}
      <p className={'text-sm text-brand-gray-dark break-words'}>
        Email: {profile.email}
        <br />
        Username: {profile.username}
      </p>
    </div>
  );
};

export default HamburgerMenu;
