import { OrderType } from 'utils/enums';

export enum AimeosEntityType {
  Product = 'product',
  ProductList = 'product/lists',
  ProductProperty = 'product/property',
  Catalog = 'catalog',
  Attribute = 'attribute',
  AttributeType = 'attribute/type',
  Customer = 'customer',
  Review = 'review',
  AggregatedReview = 'aggregate/review',
}

export interface AimeosBaseEntity {
  id: string;
  type: string;
}

export enum AimeosStatusType {
  Hidden = 2,
  Enabled = 1,
  Disabled = 0,
  Review = -1,
  Archived = -2,
}

export enum AimeosProductType {
  Article = 'default',
  Bundle = 'bundle',
  Event = 'event',
  Group = 'group',
  Selection = 'select',
  Voucher = 'voucher',
  Trade = 'trade',
}

export interface AimeosProduct extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.Product;
  attributes: {
    'product.code': string;
    'product.config': any[];
    'product.ctime': string;
    'product.dataset': string;
    'product.id': string;
    'product.label': string;
    'product.mtime': string;
    'product.status': AimeosStatusType;
    'product.type': AimeosProductType;
    can_be_reviewed: Nullable<boolean>;
    units_price: Nullable<string>;
    units_amount: Nullable<number>;
    delivery_date: Nullable<string>;
    delivery_location: Nullable<string>;
  };
}

export interface AimeosProductProperty extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.ProductProperty;
  attributes: {
    'product.property.id': string;
    'product.property.type': string;
    'product.property.value': string;
  };
}

export interface AimeosProductList extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.ProductList;
  attributes: {
    'product.lists.id': string;
    'product.lists.type': string;
    'product.lists.domain': AimeosEntityType;
    'product.lists.parentid': string;
    'product.lists.refid': string;
  };
}

export interface AimeosAttribute extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.Attribute;
  attributes: {
    'attribute.id': string;
    'attribute.code': string;
    'attribute.label': string;
    'attribute.type': string;
  };
}

export interface AimeosAttributeType extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.AttributeType;
  attributes: {
    'attribute.type.code': string;
    'attribute.type.id': string;
    'attribute.type.name': string;
    'attribute.type.label': string;
    'attribute.type.status': number;
  };
}

export interface AimeosCatalog extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.Catalog;
  attributes: {
    'catalog.status': number;
    'catalog.id': string;
    'catalog.code': string;
    'catalog.label': string;
    'catalog.level': number;
    'catalog.parentid': string;
  };
}

export interface AimeosCustomer extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.Customer;
  attributes: {
    'customer.id': string;
    'customer.code': string;
    username: string;
  };
}

export interface AimeosReview extends AimeosBaseEntity {
  id: string;
  type: AimeosEntityType.Review;
  attributes: {
    product_code: string;
    product_customerid: string;
    product_customerid_username: string;
    product_type: OrderType;
    'review.comment': string;
    'review.ctime': string;
    'review.customerid': string;
    'review.domain': string;
    'review.editor': string;
    'review.id': string;
    'review.mtime': string;
    'review.name': string;
    'review.orderproductid': string;
    'review.rating': number;
    'review.refid': string;
    'review.response': string;
    'review.siteid': string;
    'review.status': number;
  };
}

enum AggregateRatingEnumType {
  AverageProduct = 'rating_avg_product_id',
  AverageCustomer = 'rating_avg_customer_id',
  AverageProductCustomer = 'rating_avg_product_customer_id',
}

export interface AimeosAggregatedReview extends AimeosBaseEntity {
  id: string;
  type: AggregateRatingEnumType;
  attributes: string;
}

export type AimeosRelationships = {
  customer?: { data: { id: string; type: AimeosEntityType.Customer }[] };
  catalog?: { data: { id: string; type: AimeosEntityType.Catalog }[] };
  attribute?: { data: { id: string; type: AimeosEntityType.Attribute }[] };
  'attribute/type'?: { data: { id: string; type: AimeosEntityType.AttributeType }[] };
  product?: { data: { id: string; type: AimeosEntityType.Product }[] };
  'product/property'?: { data: { id: string; type: AimeosEntityType.ProductProperty }[] };
  'product/lists'?: { data: { id: string; type: AimeosEntityType.ProductList }[] };
};

export type IncludedEntities = (
  | AimeosCustomer
  | AimeosProduct
  | AimeosProductProperty
  | AimeosAttribute
  | AimeosAttributeType
  | AimeosCatalog
  | AimeosProductList
)[];

export type AimeosWithRelationships = {
  relationships?: AimeosRelationships;
  // public api includes those entities:
  catalog?: AimeosCatalog;
  attribute?: AimeosAttribute;
};

export type AimeosSingleResponse<T extends AimeosBaseEntity> = {
  data: T & AimeosWithRelationships;
  included?: IncludedEntities;
};
export type AimeosManyResponse<T extends AimeosBaseEntity> = {
  data: (T & AimeosWithRelationships)[];
  included?: IncludedEntities;
  meta: {
    total: number;
  };
};

export enum AimeosCatalogLevel {
  Category = 0,
  Product = 1,
  Variety = 2,
}

export enum AimeosProductFilterBy {
  Time = 'product.ctime',
  Code = 'product.code',
  Price = 'units_price',
  Amount = 'units_amount',
  DeliveryDate = 'delivery_date',
}
