import { setUserId } from './analytics';
import { cachedQueryClient, memoryQueryClient } from '../providers/TanstackQuery';

export const SESSION_STORAGE_KEYS = {
  HIDDEN_PENDING_ORDERS: 'HIDDEN_PENDING_ORDERS',
  READ_PHISHING_TERM: 'rd_psh_lnk',
};

export function getHiddenPendingOrders() {
  let hiddenOrders: string[];
  try {
    hiddenOrders = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEYS.HIDDEN_PENDING_ORDERS) || '[]');
  } catch (err) {
    console.error(err);
    hiddenOrders = [];
  }
  return hiddenOrders;
}

export function hidePendingOrder(orderId: string) {
  const orders = getHiddenPendingOrders();
  orders.push(orderId);
  window.sessionStorage.setItem(SESSION_STORAGE_KEYS.HIDDEN_PENDING_ORDERS, JSON.stringify(orders));
}

export function getReadPhishingTerm() {
  try {
    const read = window.localStorage.getItem(SESSION_STORAGE_KEYS.READ_PHISHING_TERM);
    return read === '1';
  } catch (err) {
    console.error(err);
  }
  return false;
}

export function acceptReadPhishingTerm() {
  window.localStorage.setItem(SESSION_STORAGE_KEYS.READ_PHISHING_TERM, '1');
}

export const appLogout = (redirectTo?: string) => {
  sessionStorage.clear();
  localStorage.clear();
  location.replace(redirectTo ? redirectTo : '/');
  memoryQueryClient.clear();
  cachedQueryClient.clear();
  setUserId(null);
};
