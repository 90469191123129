import { Outlet } from 'react-router-dom';
import ModalsProvider from 'providers/Modals';
import VisibleContentProvider from 'providers/VisibleContent';
import DataValuesProvider from 'providers/DataValues';
import useWatchMyPendingOrders from 'hooks/useWatchMyPendingOrders';
import ChatModalProvider from 'providers/ChatModalProvider';
import OnboardingManager from 'components/OnboardingManager';
import useForegroundNotificationListener from '../../hooks/useForegroundNotificationListener';

function WatchersCollection() {
  useWatchMyPendingOrders();
  useForegroundNotificationListener();

  return null;
}

export default function ClientProvidersWrapper() {
  return (
    <DataValuesProvider>
      <VisibleContentProvider>
        <ModalsProvider>
          <ChatModalProvider>
            <WatchersCollection />

            <Outlet />

            <OnboardingManager />
          </ChatModalProvider>
        </ModalsProvider>
      </VisibleContentProvider>
    </DataValuesProvider>
  );
}
