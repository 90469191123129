import { useQuery } from '@tanstack/react-query';
import { memoryQueryClient } from 'providers/TanstackQuery';
import { useChatModal } from 'providers/ChatModalProvider';

export const getChatMembersQueryKey = (channelId: string) => ['chat-members', channelId] as const;

export default function useChatMembersQuery(channelId: string) {
  const { chatClient } = useChatModal();
  return useQuery(
    {
      queryKey: getChatMembersQueryKey(channelId),
      queryFn: () => {
        return chatClient?.objects
          .getChannelMembers({
            channel: channelId,
            include: { customUUIDFields: true, customFields: true },
          })
          .then((res) => res.data);
      },
      enabled: !!chatClient && !!channelId,
      networkMode: 'offlineFirst',
      staleTime: 1000 * 60,
    },
    memoryQueryClient,
  );
}
