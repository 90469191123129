export const orderCodeFormatter = new Intl.NumberFormat('en', {
  useGrouping: false,
  minimumIntegerDigits: 6,
  style: 'decimal',
});

export const priceFieldValueFormatter = new Intl.NumberFormat('en', {
  useGrouping: false,
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const unitsFormatter = new Intl.NumberFormat('en', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const priceFormatter = new Intl.NumberFormat('en', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
