export type CSRFObject = { name: string; value: string };

export class CsrfStorage {
  private static _saved: CSRFObject = {
    name: '',
    value: '',
  };

  public static onChange(v: CSRFObject) {
    console.log(v);
  }

  public static set saved(value: CSRFObject) {
    this._saved = value;
    this.onChange(value);
  }

  public static get saved() {
    return this._saved;
  }

  public isReady() {
    return !!CsrfStorage.saved.value;
  }
}
