import { useEffect, useMemo, useState } from 'react';
import PubNub, { PubnubConfig } from 'pubnub';
import { GetMyProfileResponse } from 'api/queries/customer/me.types';

export default function useChatClient(profile?: GetMyProfileResponse) {
  const [chatClient, setChatClient] = useState<PubNub>();

  const config = useMemo(
    () =>
      profile?.chat_id && profile?.chat_token
        ? ({
            publishKey: process.env.REACT_APP_PUBNUB_PUBKEY ?? '',
            subscribeKey: process.env.REACT_APP_PUBNUB_SUBKEY ?? '',
            autoNetworkDetection: true,
            authKey: profile.chat_token || '',
            userId: profile.chat_id || '',
            keepAlive: true,
            maintainPresenceState: false,
            suppressLeaveEvents: true,
          } as PubnubConfig)
        : null,
    [profile?.chat_token, profile?.chat_id],
  );

  useEffect(() => {
    if (!chatClient && config) {
      const client = new PubNub(config);
      setChatClient(client);
    }
  }, [config, chatClient]);

  useEffect(() => {
    return () => {
      chatClient?.stop?.();
      setChatClient(undefined);
    };
  }, []);

  useEffect(() => {
    if (chatClient && profile?.chat_token) {
      const chatToken = chatClient.getToken();
      if (chatToken && chatToken !== profile.chat_token) {
        chatClient.setToken(profile.chat_token);
      }
    }
  }, [chatClient, profile?.chat_token]);

  return chatClient;
}
