import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';
import { CMSContentType, CMSData, CMSType } from './types';

export async function getAllCms(cmsType: CMSType) {
  const params = {
    include: 'text,media',
    page: {
      limit: 999,
      offset: 0,
    },
    filter: {
      '&&': [{ '~=': { 'cms.url': cmsType } }],
    },
    sort: 'cms.url',
  };
  const { data: result } = await axiosClient.query.get<CMSData>('/jsonapi/cms', { params });

  return result;
}

function parseTexts(texts: string[]) {
  try {
    return texts.map((text) => JSON.parse(text || '{}'));
  } catch (err) {
    console.error(err);
  }
  return [];
}

function selector(result: CMSData) {
  const included = result?.included || [];
  const data = result?.data || [];

  const cmsList = data.map((cms) => {
    const texts = included
      .filter((i) =>
        cms.relationships.text.data.find(
          (item) => item.id === i.id && i?.attributes?.[`text.type`] === CMSContentType.Content,
        ),
      )
      ?.map((i) => i.attributes?.['text.content']);

    const sectionLabel =
      included.filter((i) =>
        cms.relationships.text.data.find(
          (item) => item.id === i.id && i?.attributes?.[`text.type`] === CMSContentType.MetaDescription,
        ),
      )?.[0]?.attributes?.['text.label'] || '';

    return {
      id: cms?.id,
      section: sectionLabel,
      label: cms?.attributes?.['cms.label'],
      status: cms?.attributes?.['cms.status'],
      url: cms?.attributes?.['cms.url'],
      texts: parseTexts(texts),
    };
  });
  return cmsList;
}

export type AllCmsQueryType = typeof selector;

export const getAllCmsQueryKey = (cmsType: CMSType) => ['cms', 'all', cmsType] as const;

export function useGetAllCmsQuery(cmsType: CMSType, enabled = true) {
  return useQuery({
    queryKey: getAllCmsQueryKey(cmsType),
    queryFn: () => getAllCms(cmsType),
    select: selector,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
}
