import PubNub from 'pubnub';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { GetMyProfileResponse } from 'api/queries/customer/me.types';
import useLoadMemberships from './useLoadMemberships';
import { ChatReducerAction } from '../reducer/types';

export default function useMembershipsLoader(
  dispatch: Dispatch<ChatReducerAction>,
  chatClient?: PubNub,
  profile?: GetMyProfileResponse,
) {
  const loadMemberships = useLoadMemberships(chatClient);
  const [loading, setLoading] = useState(false);

  const checkAndLoad = useCallback(async () => {
    if (chatClient) {
      let prevAmount = 0;
      let cursor: string | undefined = undefined;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const response = await loadMemberships(cursor);
        if (response) {
          dispatch({ type: 'LOADED_MEMBERSHIP', payload: response[0] });
          dispatch({ type: 'LOADED_LAST_MESSAGE', payload: response[1] });
          prevAmount += 50;

          if (typeof response[0].totalCount === 'number' && response[0].totalCount > prevAmount) {
            cursor = response[0].next;
            continue;
          }
        }

        break;
      }
    }
  }, [chatClient, dispatch, loadMemberships]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      profile?.chat_token && chatClient?.setToken?.(profile.chat_token);
      checkAndLoad()
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [checkAndLoad, profile?.chat_token]);

  return loading;
}
