import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'flowbite-react';
import logoSrc from 'assets/png/logo-authorized.png';
import { MailIcon } from 'assets/svg/Mail';
import { useModals } from 'providers/Modals';
import { KEYS, logEvent } from 'utils/analytics';
import useCheckOrderCreationAccess from 'hooks/useCheckOrderCreationAccess';
import ProfileIcon from 'assets/svg/Profile';
import { useDataValues } from 'providers/DataValues';
import MenuButton from './components/MenuButton';
import ChatButton from './components/ChatButton';
import { OnboardingManagerTooltips, OnboardingStep } from '../OnboardingManager/config';

export default function Header() {
  const { open: openModal } = useModals();

  const { pathname } = useLocation();
  const { profile } = useDataValues();
  const [checkOrdersAndContinue, isCheckingOrders] = useCheckOrderCreationAccess();

  const onClickPlaceOrder = () => {
    checkOrdersAndContinue(() => openModal('buy-sell-order-prompt'));
    logEvent(KEYS.click_place_order);
  };

  return (
    <header className="flex flex-row h-[46px] w-full justify-between items-center top-0 fixed z-20 bg-white px-4 md:px-8 shadow-s-gray">
      <div className="flex space-x-4">
        <div className="flex flex-row items-center h-[46px] mr-2">
          <img src={logoSrc} className="h-[26px] w-[101px] object-fit-contain" alt={'Smoove Exchange'} />
        </div>
        <div className="hidden md:flex space-x-1 items-center">
          <Link
            id={OnboardingManagerTooltips[OnboardingStep.Dashboard].desktop.elementId}
            data-tooltip-id={OnboardingManagerTooltips[OnboardingStep.Dashboard].desktop.tooltipId}
            to="/"
            className={clsx(
              'text-xs px-4 py-1 rounded-full whitespace-nowrap',
              pathname === '/' ? 'bg-black text-white' : 'bg-transparent text-black',
            )}
          >
            {'Dashboard'}
          </Link>

          <Link
            id={OnboardingManagerTooltips[OnboardingStep.Marketplace].desktop.elementId}
            data-tooltip-id={OnboardingManagerTooltips[OnboardingStep.Marketplace].desktop.tooltipId}
            to="/marketplace"
            className={clsx(
              'text-xs px-4 py-1 rounded-full whitespace-nowrap',
              pathname === '/marketplace' ? 'bg-black text-white' : 'bg-transparent text-black',
            )}
          >
            {'Marketplace'}
          </Link>

          <Link
            id={OnboardingManagerTooltips[OnboardingStep.MyOrders].desktop.elementId}
            data-tooltip-id={OnboardingManagerTooltips[OnboardingStep.MyOrders].desktop.tooltipId}
            to="/orders"
            className={clsx(
              'text-xs px-4 py-1 rounded-full whitespace-nowrap',
              pathname === '/orders' ? 'bg-black text-white' : 'bg-transparent text-black',
            )}
          >
            {'My Orders'}
          </Link>

          <Link
            id={OnboardingManagerTooltips[OnboardingStep.MyTrades].desktop.elementId}
            data-tooltip-id={OnboardingManagerTooltips[OnboardingStep.MyTrades].desktop.tooltipId}
            to="/trades"
            className={clsx(
              'text-xs px-4 py-1 rounded-full whitespace-nowrap',
              pathname === '/trades' ? 'bg-black text-white' : 'bg-transparent text-black',
            )}
          >
            {'My Trades'}
          </Link>
        </div>
      </div>

      <div className="hidden lg:flex">
        <Link
          to="mailto:support@smoove.world?subject=Support"
          onClick={() => {
            logEvent(KEYS.contact_smoove_support);
          }}
          className="flex flex-row justify-center items-center text-xs whitespace-nowrap"
        >
          <MailIcon className="mr-1 text-brand-purple-soft" />
          {'support@smoove.world'}
        </Link>
      </div>

      <div className="flex space-x-2 items-center md:space-x-4">
        <Button
          id={OnboardingManagerTooltips[OnboardingStep.PlaceAnOrder].desktop.elementId}
          data-tooltip-id={OnboardingManagerTooltips[OnboardingStep.PlaceAnOrder].desktop.tooltipId}
          size={'xs'}
          pill
          color={'failure'}
          className={'whitespace-nowrap bg-brand-red-main'}
          onClick={onClickPlaceOrder}
          disabled={isCheckingOrders}
          isProcessing={isCheckingOrders}
        >
          Place an Order
        </Button>

        <div className="hidden md:flex space-x-2 items-center">
          <Link to="/profile">
            <ProfileIcon isActive={pathname === '/profile'} />
          </Link>
        </div>

        {!!profile.chat_token && <ChatButton />}

        <MenuButton />
      </div>
    </header>
  );
}
