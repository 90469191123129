import { useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import LoadingIndicator from 'components/LoadingIndicator';

type Props = {
  fullPage?: boolean;
};

export default function IndicatorPageLoader({ fullPage }: Props) {
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={clsx(
        'flex flex-grow justify-center items-center w-full min-w-[320px]',
        'transition-opacity',
        visible ? 'opacity-1' : 'opacity-0',
        fullPage ? 'min-h-screen' : '',
      )}
    >
      <LoadingIndicator size={64} className={'text-brand-red-main'} />
    </div>
  );
}
