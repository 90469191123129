import { useNavigate, useParams } from 'react-router';
import { Button } from 'flowbite-react';
import { useEffect } from 'react';
import IndicatorPageLoader from 'navigation/loaders/Indicator';
import BackArrowButtonIcon from 'assets/svg/BackArrowButton';
import CustomerInfo from 'components/preview/CustomerInfo';
import StatusBadge from 'components/StatusBadge';
import { useGetOrderDetailsQuery } from 'api/queries/product/get-order';
import { AimeosProductType } from 'api/types';
import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';
import Actions from './components/Actions';
import Preview from './components/Preview';

export default function TradeDetailsPage() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { tradeId = '' } = useParams<{ tradeId: string }>();

  const { data, isFetching } = useGetOrderDetailsQuery(
    { orderId: tradeId, productType: AimeosProductType.Trade },
    true,
  );

  const hasValidData = !!data?.trade && !!data?.order;

  useEffect(() => {
    if (!isFetching) {
      if (!hasValidData) {
        const timeout = setTimeout(() => navigate('/404', { replace: true }), 1000);

        return () => clearTimeout(timeout);
      }
    }
  }, [isFetching, hasValidData, navigate]);

  if (isFetching || !data?.trade || !data?.order) {
    return <IndicatorPageLoader fullPage />;
  }

  return (
    <div className="flex flex-col p-4 md:p-10">
      <div className="flex flex-col md:flex-row justify-between md:items-center md:flex-wrap gap-2">
        <div className="flex gap-2 lg:gap-4 items-center">
          <Button
            pill
            color="light"
            className="font-semibold border-0 bg-brand-purple-light hidden md:block"
            onClick={goBack}
          >
            <span className={'hidden md:inline'}>Back</span>
          </Button>

          <button className={'hover:shadow-md md:hidden'} onClick={goBack}>
            <BackArrowButtonIcon size={26} />
          </button>

          <h2 className={'whitespace-pre-wrap text-xl md:text-[32px] leading-normal font-black text-brand-black'}>
            {data.trade.orderType} Order <span className={'font-normal break-words'}>{data.order?.code}</span>
          </h2>

          <div className={'ml-auto md:hidden'} />

          <StatusBadge status={data.trade.tradeStatus} />
        </div>

        <HorizontalDashedDelimiter strokeLinecap={'round'} strokeDasharray={'0.1 6'} className={'md:hidden mt-1'} />

        <CustomerInfo userId={data?.order?.owner || ''} navigateToProfile />

        <HorizontalDashedDelimiter strokeLinecap={'round'} strokeDasharray={'0.1 6'} className={'md:hidden'} />
      </div>

      <div className={'h-4 md:h-12'} />

      <Preview {...data.trade!} />

      <div className={'h-7 md:h-16'} />

      <Actions trade={data.trade} order={data.order} />

      <div className={'h-8'} />
    </div>
  );
}
