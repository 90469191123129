import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';
import { createErrorHandler } from 'api/config/axios.errors';

type Params = {
  trade_id: string;
  cancel_reason: string;
};

async function cancelTrade(params: Params) {
  const result = await axiosClient.mutate.post('/api/v1/trade/cancel', params);

  return result.data;
}

const mutationKey = ['trade', 'cancel'] as const;

export function useCancelTradeMutation() {
  return useMutation({
    mutationKey,
    mutationFn: cancelTrade,
    onError: createErrorHandler(
      "You can't cancel this trade now. If you still have the same issue, please contact us to resolve it",
    ),
  });
}
