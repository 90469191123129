import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import axiosInstance from 'api/config/axios';
import { memoryQueryClient } from 'providers/TanstackQuery';
import { useDataValues } from 'providers/DataValues';
import { ProductPropertyTypeCode } from 'utils/enums';
import { AimeosEntityType, AimeosProduct, AimeosProductType, AimeosSingleResponse } from '../../types';
import { parseSingleResponse } from '../../parsers';
import { parseOrder } from '../../order.parsers';

type Params = {
  userId?: string;
  orderId: string;
  excludeChildProducts?: boolean;
  productType: AimeosProductType.Trade | AimeosProductType.Article;
  attrId?: string;
};

async function getOrderDetails({ userId, orderId, productType, excludeChildProducts, attrId }: Params) {
  const response = await axiosInstance.query.get<AimeosSingleResponse<AimeosProduct>>(
    '/admin/default/jsonadm/product',
    {
      params: {
        page: {
          limit: 1,
          offset: 0,
        },
        include: [
          AimeosEntityType.ProductProperty,
          excludeChildProducts ? '' : AimeosEntityType.Product,
          AimeosEntityType.Attribute,
          AimeosEntityType.Catalog,
        ]
          .filter(Boolean)
          .join(','),
        filter: {
          '&&': [
            { '==': { 'product.status': 1 } },
            { '==': { 'product.type': productType } },
            { '==': { 'product.id': orderId } },
            userId && {
              '!=': {
                [`product:prop("${
                  productType === AimeosProductType.Article
                    ? ProductPropertyTypeCode.Creator
                    : ProductPropertyTypeCode.TradeCreator
                }",null,"${userId}")`]: userId,
              },
            },
            attrId && {
              '!=': {
                [`product:has("attribute","default","${attrId}")`]: attrId,
              },
            },
          ].filter(Boolean),
        },
      },
    },
  );

  const mainProduct = parseSingleResponse<AimeosEntityType.Product>(response.data);

  const childProductId = mainProduct.products
    .sort((p1, p2) => dayjs(p1.createdAt).unix() - dayjs(p2.createdAt).unix())
    .filter((pr) => {
      if (productType === AimeosProductType.Article) {
        return pr.productType === AimeosProductType.Trade;
      }
      return pr.productType === AimeosProductType.Article;
    })
    .map((pr) => pr.id)
    .pop();

  let childProduct: typeof mainProduct | null = null;

  if (childProductId && !excludeChildProducts) {
    const response = await axiosInstance.query.get<AimeosSingleResponse<AimeosProduct>>(
      '/admin/default/jsonadm/product',
      {
        params: {
          page: {
            limit: 1,
            offset: 0,
          },
          include: [AimeosEntityType.ProductProperty, AimeosEntityType.Attribute, AimeosEntityType.Catalog].join(','),
          filter: {
            '&&': [
              { '==': { 'product.status': 1 } },
              {
                '==': {
                  'product.type':
                    productType === AimeosProductType.Article ? AimeosProductType.Trade : AimeosProductType.Article,
                },
              },
              { '==': { 'product.id': childProductId } },
            ],
          },
        },
      },
    );

    childProduct = parseSingleResponse<AimeosEntityType.Product>(response.data);
  }

  if (productType === AimeosProductType.Article) {
    return {
      order: parseOrder(mainProduct),
      trade: childProduct ? parseOrder(childProduct) : null,
    };
  }

  return {
    order: childProduct ? parseOrder(childProduct) : null,
    trade: parseOrder(mainProduct),
  };
}

export const getOrderBaseQueryKey = ['order', 'get-one'] as const;

export const getQueryKey = (params: Params) =>
  [
    ...getOrderBaseQueryKey,
    params.userId,
    params.orderId,
    params.excludeChildProducts || false,
    params.attrId || '',
    params.productType,
  ] as const;

export function useGetOrderDetailsQuery(params: Omit<Params, 'userId'>, filterByUser = false, enabled = true) {
  const { userId } = useDataValues();

  const allParams = {
    ...params,
    userId: filterByUser ? userId : '',
  };

  return useQuery(
    {
      queryKey: getQueryKey(allParams),
      queryFn: () => getOrderDetails(allParams),
      enabled: !!userId && enabled,
    },
    memoryQueryClient,
  );
}
