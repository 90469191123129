import { Outlet } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Header from 'components/header/Header';
import NotificationMenuMobileLayout from 'components/topBarMenuModals/notificationMenu/NotificationMenuMobile';
import HamburgerMenuMobileLayout from 'components/topBarMenuModals/hamburgerMenu/HamburgerMenuMobile';
import { useIsTabletAndLess } from 'hooks/media-query';
import { useVisibleContent } from 'providers/VisibleContent';

function ContentVariant({ visible, children }: PropsWithChildren<{ visible: boolean }>) {
  return <div className={clsx('flex-col flex-grow', visible ? 'flex' : 'hidden')}>{children}</div>;
}

export default function AuthorizedBackgroundMinimizedWrapper() {
  const { visibleContent, setVisibleContent } = useVisibleContent();
  const isTabletAndLess = useIsTabletAndLess();

  return (
    <div className="bg-white min-h-screen w-full h-full flex flex-col justify-between pt-[46px]">
      <Header />

      <ContentVariant visible={visibleContent === 'main'}>
        <Outlet />
      </ContentVariant>

      {isTabletAndLess && (
        <>
          <ContentVariant visible={visibleContent === 'notifications'}>
            <NotificationMenuMobileLayout />
          </ContentVariant>

          <ContentVariant visible={visibleContent === 'menu'}>
            <HamburgerMenuMobileLayout
              onPressItem={() => {
                setVisibleContent('main');
              }}
            />
          </ContentVariant>
        </>
      )}
    </div>
  );
}
