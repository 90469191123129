import { createContext, PropsWithChildren, Suspense, useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import modalComponents, { ModalName, ModalProps } from 'components/modals';
import IndicatorPageLoader from '../navigation/loaders/Indicator';
import CloseRoundedIcon from '../assets/svg/CloseRounded';

type ModalsContextType = {
  openedModal?: ModalName;
  modalProps?: any;
  closeable: boolean;
  open<T extends ModalName>(modal: T, props?: ModalProps<T>, closeable?: boolean): void;
  close(): void;
};

const ModalsContext = createContext<ModalsContextType>({
  openedModal: undefined,
  modalProps: undefined,
  closeable: true,
  open() {},
  close() {},
});

export default function ModalsProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState<Pick<ModalsContextType, 'openedModal' | 'modalProps' | 'closeable'>>({
    closeable: true,
  });

  const callbacks = useMemo<Pick<ModalsContextType, 'open' | 'close'>>(
    () => ({
      open: (modal, props, closeable = true) =>
        setState((prev) => {
          if (!prev.closeable) {
            return prev;
          }

          return {
            openedModal: modal,
            modalProps: props,
            closeable,
          };
        }),
      close: () =>
        setState({
          openedModal: undefined,
          modalProps: undefined,
          closeable: true,
        }),
    }),
    [],
  );

  const OpenedModal = state.openedModal ? modalComponents[state.openedModal] : null;

  return (
    <ModalsContext.Provider value={{ ...state, ...callbacks }}>
      {children}

      {!!OpenedModal && (
        <div
          className={clsx(
            'fixed bottom-0 left-0 right-0 top-0 z-50 bg-brand-blue-dark/40 min-w-[328px]',
            'flex flex-col justify-center items-center',
          )}
          onClick={state.closeable ? callbacks.close : undefined}
        >
          <section
            className={clsx('block w-full max-w-[512px] relative', 'px-4 md:p-0')}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={clsx(
                'block relative max-h-[95vh] overflow-y-auto overflow-x-hidden bg-white',
                'rounded-2xl border border-brand-purple-border shadow-s-purple-intensive',
                'p-4 md:p-10',
              )}
            >
              <Suspense
                fallback={
                  <div className={'flex items-center justify-center h-full w-full min-h-[50vh]'}>
                    <IndicatorPageLoader />
                  </div>
                }
              >
                <OpenedModal {...(state.modalProps || {})} />
              </Suspense>
            </div>

            {state.closeable && (
              <button
                className={'absolute -top-2 right-2 md:-right-2 hover:opacity-90 self-end'}
                onClick={callbacks.close}
              >
                <CloseRoundedIcon color="currentColor" className="h-6 w-6 text-brand-black" />
              </button>
            )}
          </section>
        </div>
      )}
    </ModalsContext.Provider>
  );
}

export const useModals = () => useContext(ModalsContext);
