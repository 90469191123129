import { forwardRef, HTMLProps } from 'react';
import clsx from 'clsx';

const TextArea = forwardRef<HTMLTextAreaElement, HTMLProps<HTMLTextAreaElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className={clsx('py-2 px-4 rounded border border-brand-purple-border text-sm font-semibold', className)}
      >
        {children}
      </textarea>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
