export type CMS = {
  attributes: {
    'cms.id': string;
    'cms.label': string;
    'cms.status': number;
    'cms.url': string;
  };
  id: string;
  links: {
    self: {
      href: string;
      allow: string[];
    };
  };
  relationships: {
    text: {
      data: {
        attributes: {
          'cms.lists.config': string;
          'cms.lists.dateend': string | null;
          'cms.lists.datestart': string | null;
          'cms.lists.domain': string;
          'cms.lists.id': string;
          'cms.lists.position': number;
          'cms.lists.refid': string;
          'cms.lists.status': number;
          'cms.lists.type': string;
        };
        id: string;
        type: string;
      }[];
    };
  };
};

export type CMSData = {
  data: CMS[];
  included: {
    id: string;
    type: string;
    attributes: {
      'text.id': string;
      'text.languageid': string | null;
      'text.type': string;
      'text.label': string;
      'text.domain': string;
      'text.content': string;
      'text.status': number;
    };
  }[];
};

export type GroupedCMSData = {
  [section: string]: {
    id: string;
    section: string;
    label: string;
    status: number;
    url: string;
    texts: {
      css: string;
      html: string;
    }[];
  }[];
};

export enum CMSType {
  FAQ = 'faq',
  TUTORIAL = 'tutorial',
}

export enum CMSContentType {
  Content = 'content',
  MetaKeyword = 'meta-keyword',
  MetaDescription = 'meta-description',
}
