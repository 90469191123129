import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PubNub from 'pubnub';
import ChatModal from 'components/chat';
import { useGetMyProfileQuery } from 'api/queries/customer/me';
import useChatClient from './hooks/useChatClient';
import { chatReducer, defaultChatReducerState } from './reducer';
import useChatListeners from './hooks/useChatListeners';
import { ProcessedChatReducerState } from './reducer/types';
import useMembershipsLoader from './hooks/useMembershipLoader';
import useChatStatePreprocessor from './hooks/useChatStatePreprocessor';

type ChatModalContextType = {
  initialTradeId?: string;
  openChat: (initialTradeId?: string) => void;
  closeChat: () => void;
  chatClient?: PubNub;
  isLoading: boolean;
  state: ProcessedChatReducerState;
  onChannelSelected(channelId: string): void;
};

const chatModalContextDefaultValue: ChatModalContextType = {
  initialTradeId: undefined,
  openChat() {},
  closeChat() {},
  state: { ...defaultChatReducerState, hasUnreadMessages: false },
  isLoading: true,
  onChannelSelected() {},
};

const ChatModalContext = createContext<ChatModalContextType>({ ...chatModalContextDefaultValue });

export const chatModalContextMethodsRef = {
  value: { ...chatModalContextDefaultValue } as Pick<ChatModalContextType, 'openChat' | 'closeChat'>,
};

export default function ChatModalProvider({ children }: PropsWithChildren) {
  const [tradeId, setTradeId] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [state, dispatch] = useReducer(chatReducer, defaultChatReducerState);

  const { data: profile, refetch: refetchProfile } = useGetMyProfileQuery();

  const chatClient = useChatClient(profile);
  const loadingMemberships = useMembershipsLoader(dispatch, chatClient, profile);
  const processedState = useChatStatePreprocessor(state, profile);
  useEffect(() => {
    if (profile?.chat_id) {
      dispatch({ type: 'UPDATE_CHAT_ID', payload: profile?.chat_id });
    }
  }, [profile?.chat_id]);
  useChatListeners(dispatch, chatClient);

  const value = useMemo(() => {
    const refObj: Pick<ChatModalContextType, 'openChat' | 'closeChat'> = {
      openChat: (initialTradeId) => {
        refetchProfile().then(({ data: response }) => {
          if (response?.chat_token && response.chat_token !== chatClient?.getToken?.()) {
            chatClient?.setToken?.(response.chat_token);
          }

          setTradeId(initialTradeId);
          setVisible(true);
        });
      },
      closeChat: () => {
        setTradeId(undefined);
        setVisible(false);
      },
    };

    chatModalContextMethodsRef.value = refObj;

    return refObj;
  }, [refetchProfile]);

  const onChannelSelected = useCallback(
    (channelId: string) => {
      if (chatClient) {
        const membership = state.memberships.find((m) => m.id === channelId);

        if (membership) {
          const lastMessageTimetoken = membership?.lastMessage?.timetoken;
          const lastReadTimetoken = membership?.lastReadMessageTimestamp;

          if (lastMessageTimetoken !== lastReadTimetoken) {
            chatClient?.objects
              .setMemberships({
                channels: [{ id: channelId, custom: { lastReadTimetoken: lastMessageTimetoken || Date.now() } }],
              })
              .catch(console.error);
          }
        }
      }

      dispatch({ type: 'SELECTED_ACTIVE_CHANNEL', payload: channelId });
    },
    [dispatch, chatClient, state],
  );

  const channelsKey = processedState.memberships
    .map((m) => m.id)
    .sort()
    .join(',');

  useEffect(() => {
    if (chatClient) {
      const channels = channelsKey.split(',');
      if (channels.length) {
        chatClient.subscribe({ channels });

        return () => {
          chatClient.unsubscribe({ channels });
        };
      }
    }
  }, [chatClient, channelsKey, visible]);

  return (
    <ChatModalContext.Provider
      value={{
        ...value,
        initialTradeId: tradeId,
        chatClient,
        state: processedState,
        isLoading: !chatClient && loadingMemberships,
        onChannelSelected,
      }}
    >
      {visible && !!chatClient && <ChatModal key={tradeId || 'default'} />}
      {children}
    </ChatModalContext.Provider>
  );
}

export const useChatModal = () => useContext(ChatModalContext);
