import { lazy, Suspense } from 'react';
import IndicatorPageLoader from 'navigation/loaders/Indicator';

const Page = lazy(() => import('./component'));

export default function PrivacyPageLoader() {
  return (
    <Suspense fallback={<IndicatorPageLoader />}>
      <Page />
    </Suspense>
  );
}
