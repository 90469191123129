import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import { appLogout } from 'utils/session';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';
import { CsrfStorage } from './csrf';

const queryAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 120000,
  responseType: 'json',
  responseEncoding: 'utf8',

  // disable unexpected cache behaviour
  // Solution: https://stackoverflow.com/a/62781874
  headers: {
    'Cache-Control': 'no-store',
    Pragma: 'no-cache',
    Expires: 0,
  },
});

const mutateAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 15000,
  withCredentials: true,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_BACKEND_BASE_URL,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

const saveCsrfOnEachResponse = function (response: AxiosResponse) {
  if (typeof response === 'object') {
    const csrf = response?.data?.meta?.csrf || response?.data?.meta?.meta?.csrf;
    if (typeof csrf === 'object' && 'name' in csrf && 'value' in csrf) {
      CsrfStorage.saved = csrf;
    }
  }

  return response;
};

const processInterceptorsQueryResponse = function (response: AxiosResponse) {
  return response;
};

const injectAuthHeader = function (config: InternalAxiosRequestConfig) {
  const access_token = localStorage.getItem('access_token');

  if (access_token) {
    try {
      config.headers.Authorization = `Bearer ${access_token}`;
    } catch (err) {
      console.error(err);
    }
  }

  try {
    const xsrfToken = Cookies.get('XSRF-TOKEN');

    if (xsrfToken) {
      config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }
  } catch (err) {
    console.error(err);
  }

  const deviceToken = localStorage.getItem(LOCAL_STORAGE_KEYS.devicePushToken);
  if (deviceToken) {
    config.headers['Device-Apn'] = deviceToken;
  }

  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  config.params = { ...config.params, dt: Date.now() + Math.random().toString(10) };

  return config;
};

const handleError = function (error: AxiosError) {
  // Do something with request error
  if (error.response?.status === 401) {
    appLogout();
  }
  return Promise.reject(error);
};

queryAxiosInstance.interceptors.request.use(injectAuthHeader, handleError);
queryAxiosInstance.interceptors.response.use(processInterceptorsQueryResponse, handleError);
mutateAxiosInstance.interceptors.request.use(injectAuthHeader, handleError);
mutateAxiosInstance.interceptors.response.use(saveCsrfOnEachResponse, handleError);

const axiosClient = {
  query: queryAxiosInstance,
  mutate: mutateAxiosInstance,
};

export default axiosClient;
