export default function DashboardIcon({ size = 28, isActive }: { size?: number; isActive?: boolean }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className={isActive ? 'fill-brand-red-main' : 'fill-brand-gray-soft'} cx="14" cy="14" r="14" />
      <path
        d="M13.3636 8.90909V11.4545C13.3636 11.9609 13.1625 12.4465 12.8045 12.8045C12.4465 13.1625 11.9609 13.3636 11.4545 13.3636H8.90909C8.40277 13.3636 7.91718 13.1625 7.55916 12.8045C7.20114 12.4465 7 11.9609 7 11.4545V8.90909C7 8.40277 7.20114 7.91718 7.55916 7.55916C7.91718 7.20114 8.40277 7 8.90909 7H11.4545C11.9609 7 12.4465 7.20114 12.8045 7.55916C13.1625 7.91718 13.3636 8.40277 13.3636 8.90909ZM19.0909 7H16.5455C16.0391 7 15.5535 7.20114 15.1955 7.55916C14.8375 7.91718 14.6364 8.40277 14.6364 8.90909V11.4545C14.6364 11.9609 14.8375 12.4465 15.1955 12.8045C15.5535 13.1625 16.0391 13.3636 16.5455 13.3636H19.0909C19.5972 13.3636 20.0828 13.1625 20.4408 12.8045C20.7989 12.4465 21 11.9609 21 11.4545V8.90909C21 8.40277 20.7989 7.91718 20.4408 7.55916C20.0828 7.20114 19.5972 7 19.0909 7ZM11.4545 14.6364H8.90909C8.40277 14.6364 7.91718 14.8375 7.55916 15.1955C7.20114 15.5535 7 16.0391 7 16.5455V19.0909C7 19.5972 7.20114 20.0828 7.55916 20.4408C7.91718 20.7989 8.40277 21 8.90909 21H11.4545C11.9609 21 12.4465 20.7989 12.8045 20.4408C13.1625 20.0828 13.3636 19.5972 13.3636 19.0909V16.5455C13.3636 16.0391 13.1625 15.5535 12.8045 15.1955C12.4465 14.8375 11.9609 14.6364 11.4545 14.6364ZM19.0909 14.6364H16.5455C16.0391 14.6364 15.5535 14.8375 15.1955 15.1955C14.8375 15.5535 14.6364 16.0391 14.6364 16.5455V19.0909C14.6364 19.5972 14.8375 20.0828 15.1955 20.4408C15.5535 20.7989 16.0391 21 16.5455 21H19.0909C19.5972 21 20.0828 20.7989 20.4408 20.4408C20.7989 20.0828 21 19.5972 21 19.0909V16.5455C21 16.0391 20.7989 15.5535 20.4408 15.1955C20.0828 14.8375 19.5972 14.6364 19.0909 14.6364Z"
        className={isActive ? 'fill-white' : 'fill-brand-purple-gray'}
      />
    </svg>
  );
}
