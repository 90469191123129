import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { GetMyProfileResponse } from 'api/queries/customer/me.types';
import { useGetMyProfileQuery } from 'api/queries/customer/me';
import { ParsedAppValuesObject, useGetAllValuesQuery } from 'api/queries/common/all-values';
import { setUserId } from 'utils/analytics';
import { useAppState } from './AppState';

type DataValuesContextType = {
  profile: Partial<GetMyProfileResponse>;
  isOnboarded: boolean;
  userId: string;
  appValues?: ParsedAppValuesObject;
  loading: boolean;
};

const DataValuesContext = createContext<DataValuesContextType>({
  profile: {},
  isOnboarded: true,
  userId: '',
  appValues: undefined,
  loading: false,
});

export default function DataValuesProvider({ children }: PropsWithChildren) {
  const { csrfReady } = useAppState();

  const { data: profile, isLoading: loadingProfile } = useGetMyProfileQuery(csrfReady);

  const { data: appValues, isLoading: loadingAppValues } = useGetAllValuesQuery(!!profile);

  const loading = [loadingProfile, loadingAppValues].some(Boolean);

  useEffect(() => {
    setUserId(profile?.id ? profile.id.toString() : null);
  }, [profile?.id]);

  return (
    <DataValuesContext.Provider
      value={{
        profile: profile || {},
        isOnboarded: typeof profile?.onboarded === 'boolean' ? profile.onboarded : true,
        userId: (profile?.id ?? '').toString(),
        appValues,
        loading,
      }}
    >
      {children}
    </DataValuesContext.Provider>
  );
}

export const useDataValues = () => useContext(DataValuesContext);
