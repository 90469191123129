import { Reducer } from 'react';
import { ObjectCustom, UUIDMembershipObject } from 'pubnub';
import { getChatMembersQueryKey } from 'components/chat/hooks/members.query';
import { ChatReducerAction, ChatReducerState } from './types';
import { memoryQueryClient } from '../../TanstackQuery';

export const defaultChatReducerState: ChatReducerState = {
  memberships: [],
  chatId: '',
};

export const chatReducer: Reducer<ChatReducerState, ChatReducerAction> = (prevState, action) => {
  if (action.type === 'LOADED_MEMBERSHIP') {
    const state = { ...prevState };

    const arr = [...state.memberships];

    action.payload.data.forEach((membership) => {
      if (!arr.find((a) => a.id === membership.channel.id)) {
        arr.push({
          id: membership.channel.id,
          name: (membership.channel as any).name,
          updatedAt: membership.updated,
          hasMessages: false,
          lastReadMessageTimestamp: +(membership.custom?.lastReadTimetoken || 0) || undefined,
          custom: (membership.channel as any).custom,
        });
      }
    });

    state.memberships = arr;

    return state;
  }

  if (action.type === 'LOADED_LAST_MESSAGE') {
    const state = { ...prevState };

    const arr = [...state.memberships];

    Object.entries(action.payload.channels).forEach(([channelId, messages]) => {
      const index = arr.findIndex((c) => c.id === channelId);
      if (index !== -1) {
        const message = messages?.[0];
        if (message) {
          const timetoken = +message.timetoken / 10000;
          arr[index] = {
            ...arr[index],
            updatedAt: timetoken || arr[index].updatedAt,
            hasMessages: true,
            lastMessage: {
              text: message.message.text,
              timetoken,
              uuid: message.uuid || '',
            },
          };
        }
      }
    });

    state.memberships = arr;

    return state;
  }

  if (action.type === 'MESSAGE_EVENT') {
    const state = { ...prevState };

    const arr = [...state.memberships];

    const index = arr.findIndex((c) => c.id === action.payload.channel);

    if (index !== -1) {
      const timetoken = +action.payload.timetoken / 10000;
      arr[index].lastMessage = {
        text: action.payload.message.text,
        timetoken,
        uuid: action.payload.publisher,
      };
      arr[index].updatedAt = timetoken;
    }

    state.memberships = arr;

    return state;
  }

  if (action.type === 'OBJECTS_EVENT') {
    const state = { ...prevState };

    const arr = [...state.memberships];

    if (action.payload.message.type === 'membership' && action.payload.message.event === 'set') {
      const channelId = (action.payload.message.data as any)?.channel?.id;
      const index = arr.findIndex((c) => c.id === channelId);

      if (index !== -1) {
        const ownUser = (action.payload.message.data as any).uuid.id === state.chatId;
        if (ownUser) {
          arr[index].lastReadMessageTimestamp = action.payload.message.data?.custom?.lastReadTimetoken
            ? +action.payload.message.data?.custom?.lastReadTimetoken
            : arr[index].lastReadMessageTimestamp;
        }

        memoryQueryClient.setQueryData(getChatMembersQueryKey(channelId), (prev) => {
          if (prev) {
            try {
              const data = prev as UUIDMembershipObject<ObjectCustom, ObjectCustom>[];

              if (Array.isArray(data)) {
                const mems = [...data];

                const inner_index = mems.findIndex((c) => c.uuid.id === (action.payload.message.data as any).uuid.id);

                if (inner_index !== -1) {
                  mems[inner_index].custom = {
                    ...mems[inner_index].custom,
                    lastReadTimetoken: arr[index].lastReadMessageTimestamp || Date.now(),
                  };
                }

                return mems;
              }
            } catch (err) {
              console.error(err);
            }
          }
          return prev;
        });
      }
    }

    state.memberships = arr;

    return state;
  }

  if (action.type === 'SELECTED_ACTIVE_CHANNEL') {
    const state = { ...prevState };

    const arr = [...state.memberships];

    const index = arr.findIndex((c) => c.id === action.payload);

    if (index !== -1) {
      arr[index].lastReadMessageTimestamp = Date.now();
    }

    state.memberships = arr;

    return state;
  }

  if (action.type === 'UPDATE_CHAT_ID') {
    const state = { ...prevState };

    state.chatId = action.payload;

    return state;
  }

  return prevState;
};
