import { closeSnackbar, SnackbarProviderProps } from 'notistack';
import * as z from 'zod';
import { CurrencyOptions, LocaleOptions } from '../providers/AppState/types';

export const DEFAULT_LOCALE: LocaleOptions = {
  code: 'en',
  name: 'English',
};

export const DEFAULT_CURRENCY: CurrencyOptions = {
  code: 'USD',
  symbol: '$',
};

export const NOTISTACK_OPTIONS: SnackbarProviderProps = {
  anchorOrigin: { horizontal: 'center', vertical: 'top' },
  action: (notifyId) => (
    <button
      onClick={() => closeSnackbar(notifyId)}
      className="text-sm font-semibold px-2 py-1 bg-white/30 hover:bg-white/40 rounded"
    >
      OK
    </button>
  ),
};

export const passwordSchema = z.string().regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/, {
  message:
    'Your password must be 8-20 characters long, contain letters and numbers (no spaces, special characters or emoji)',
});

export const LOCAL_STORAGE_KEYS = {
  marketLastFilter: 'mrk-l-f',
  marketSavedFilters: 'mrk-s-fs',
  devicePushToken: 'dev-p-t',
};

export const BLANK_DATA_ITEM: DataItem = {
  id: '',
  label: '',
  value: '',
};

export const FROZEN_CHANNEL_STATUS = 'frozen';
export const MAX_CHAT_MEMBERSHIP_FETCH_COUNT = 50;
export const DISPLAY_DATE_TEMPLATE = 'DD MMM. YYYY'; // 01 Jun. 2024

export const getAppInfo = () => {
  const appInfo = ((window as any).__appInfo || {}) as { version: string; versionCode: string; packageName: string };

  appInfo.packageName = appInfo.packageName || location.host;

  return appInfo;
};

export const getFormattedAppInfo = () => {
  const { version, versionCode, packageName = location.host } = getAppInfo();

  const mobile = version ? ` | Version ${version} (build ${versionCode})` : '';

  return `${packageName}${mobile}`;
};
