export const TrashIcon = ({ size = 20, color = 'currentColor', filled: _1, ...props }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="10" fill="#EBEBF8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9704 6.85742H6.0293V8.2506C6.5166 8.2506 6.91164 8.66643 6.91164 9.17939V11.037C6.91164 12.3505 6.91164 13.0072 7.29929 13.4153C7.68694 13.8233 8.31085 13.8233 9.55867 13.8233H10.441C11.6888 13.8233 12.3127 13.8233 12.7004 13.4153C13.088 13.0072 13.088 12.3505 13.088 11.037V9.17939C13.088 8.66643 13.4831 8.2506 13.9704 8.2506V6.85742ZM9.33808 9.17939C9.33808 8.92291 9.14056 8.71499 8.89691 8.71499C8.65326 8.71499 8.45574 8.92291 8.45574 9.17939V11.5014C8.45574 11.7578 8.65326 11.9657 8.89691 11.9657C9.14056 11.9657 9.33808 11.7578 9.33808 11.5014V9.17939ZM11.5439 9.17939C11.5439 8.92291 11.3464 8.71499 11.1028 8.71499C10.8591 8.71499 10.6616 8.92291 10.6616 9.17939V11.5014C10.6616 11.7578 10.8591 11.9657 11.1028 11.9657C11.3464 11.9657 11.5439 11.7578 11.5439 11.5014V9.17939Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6198 5.0563C10.4327 5.01853 10.2154 5 9.99952 5C9.78368 5 9.56631 5.01853 9.37927 5.0563C9.28576 5.07518 9.19422 5.09998 9.11128 5.13264C9.03595 5.16231 8.9332 5.21157 8.84628 5.29694C8.66813 5.47191 8.65845 5.76577 8.82467 5.9533C8.98152 6.13026 9.23894 6.1493 9.41716 6.00387C9.41817 6.00345 9.41928 6.00301 9.42048 6.00253C9.44402 5.99326 9.48491 5.98069 9.5455 5.96845C9.66665 5.94399 9.82689 5.92879 9.99952 5.92879C10.1721 5.92879 10.3324 5.94399 10.4535 5.96845C10.5141 5.98069 10.555 5.99326 10.5786 6.00253C10.5798 6.00301 10.5809 6.00345 10.5819 6.00387C10.7601 6.1493 11.0175 6.13026 11.1744 5.9533C11.3406 5.76577 11.3309 5.47191 11.1528 5.29694C11.0658 5.21157 10.9631 5.16231 10.8878 5.13264C10.8048 5.09998 10.7133 5.07518 10.6198 5.0563Z"
        fill={color}
      />
    </svg>
  );
};
