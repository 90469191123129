import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';
import type { LogoutResponse } from './logout.types';

export async function logoutUser() {
  return axiosClient.mutate.post<LogoutResponse>(
    '/api/v1/logout',
    {},
    {
      timeout: 3000,
    },
  );
}

const mutationKey = ['auth', 'logout'] as const;

export function useLogoutMutation() {
  return useMutation({
    mutationKey,
    mutationFn: logoutUser,
  });
}
