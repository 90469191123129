export enum OnboardingStep {
  Dashboard,
  ReferencePositions,
  PlaceAnOrder,
  Marketplace,
  MyOrders,
  MyTrades,
  BuyOrderMarketplace,
  SellOrderMarketplace,
}

export const OnboardingManagerTooltips = {
  [OnboardingStep.Dashboard]: {
    desktop: {
      elementId: 'dashboard-tab',
      tooltipId: 'onboard-manager-dashboard',
    },
    mobile: {
      elementId: 'dashboard-tab-mobile',
      tooltipId: 'onboard-manager-dashboard-mobile',
    },
    path: '/',
    title: 'Welcome to Smoove Exchange!',
    description: 'Stay informed at a glance – Your command center for market insights and trends',
  },
  [OnboardingStep.ReferencePositions]: {
    desktop: {
      elementId: 'reference-positions-list',
      tooltipId: 'onboard-manager-reference-positions',
    },
    mobile: {
      elementId: 'reference-positions-list',
      tooltipId: 'onboard-manager-reference-positions',
    },
    path: '/',
    title: 'Top marketplace hubs',
    description: 'Quickly find marketplaces that have been bookmarked for easy access',
  },
  [OnboardingStep.PlaceAnOrder]: {
    desktop: {
      elementId: 'place-an-order-btn',
      tooltipId: 'onboard-manager-place-order',
    },
    mobile: {
      elementId: 'place-an-order-btn',
      tooltipId: 'onboard-manager-place-order',
    },
    path: '/',
    title: 'Do you want to buy or sell a product?',
    description: 'Launch your trade: Begin with a single click',
  },
  [OnboardingStep.Marketplace]: {
    desktop: {
      elementId: 'marketplace-tab',
      tooltipId: 'onboard-manager-marketplace',
    },
    mobile: {
      elementId: 'marketplace-tab-mobile',
      tooltipId: 'onboard-manager-marketplace-mobile',
    },
    path: '/',
    title: 'Market gateway',
    description: 'Discover agricultural produce trading opportunities on our digital marketplace',
  },
  [OnboardingStep.MyOrders]: {
    desktop: {
      elementId: 'my-orders-tab',
      tooltipId: 'onboard-manager-my-orders',
    },
    mobile: {
      elementId: 'my-orders-tab-mobile',
      tooltipId: 'onboard-manager-my-orders-mobile',
    },
    path: '/',
    title: 'Track your orders',
    description: 'Review all orders placed, both active and completed',
  },
  [OnboardingStep.MyTrades]: {
    desktop: {
      elementId: 'my-trades-tab',
      tooltipId: 'onboard-manager-my-trades',
    },
    mobile: {
      elementId: 'my-trades-tab-mobile',
      tooltipId: 'onboard-manager-my-trades-mobile',
    },
    path: '/',
    title: 'Track your trade history',
    description: 'Review all your completed trades that have been executed on the exchange',
  },
  [OnboardingStep.BuyOrderMarketplace]: {
    desktop: {
      elementId: 'marketplace-buy-orders-list',
      tooltipId: 'onboard-manager-buy-order-marketplace',
    },
    mobile: {
      elementId: 'marketplace-buy-orders-list',
      tooltipId: 'onboard-manager-buy-order-marketplace',
    },
    path: '/marketplace',
    title: 'Buy Orders',
    description: 'Review current buy orders from users looking to purchase goods',
  },
  [OnboardingStep.SellOrderMarketplace]: {
    desktop: {
      elementId: 'marketplace-sell-orders-list',
      tooltipId: 'onboard-manager-sell-order-marketplace',
    },
    mobile: {
      elementId: 'marketplace-sell-orders-list',
      tooltipId: 'onboard-manager-sell-order-marketplace',
    },
    path: '/marketplace',
    title: 'Sell Orders',
    description: 'Review current sell orders from users looking to sell goods',
  },
};
