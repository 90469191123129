import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { useIsTabletAndLess } from 'hooks/media-query';

export type VisibleContent = 'main' | 'notifications' | 'menu';

export type VisibleContentContextType = {
  visibleContent: VisibleContent;
  setVisibleContent: Dispatch<SetStateAction<VisibleContent>>;
};

const VisibleContentContext = createContext<VisibleContentContextType>({
  visibleContent: 'main',
  setVisibleContent() {},
});

export default function VisibleContentProvider({ children }: PropsWithChildren) {
  const [showContent, setShowContent] = useState<VisibleContent>('main');
  const isTabletAndLess = useIsTabletAndLess();

  const visibleContent: VisibleContent = isTabletAndLess ? showContent : 'main';

  return (
    <VisibleContentContext.Provider
      value={{
        visibleContent,
        setVisibleContent: setShowContent,
      }}
    >
      {children}
    </VisibleContentContext.Provider>
  );
}

export const useVisibleContent = () => useContext(VisibleContentContext);
