import { ParsedCatalog, parseManyResponse } from './parsers';
import { AimeosEntityType, AimeosProductType } from './types';
import {
  attributeCodeToOrderStatusMapping,
  attributeCodeToUnitTypeMapping,
  CommonAttributeTypeCode,
  DeliveryAttributeCode,
  OrderDeliveryType,
  OrderStatus,
  OrderStatusAttributeCode,
  OrderType,
  OrderTypeAttributeCode,
  OrderUnitsType,
  ProductPropertyTypeCode,
  TradeStatusAttributeCode,
  tradeStatusAttributeCodeToOrderStatusMapping,
  UnitAttributeCode,
} from '../utils/enums';

type ParsedManyResponseOrder = ReturnType<typeof parseManyResponse<AimeosEntityType.Product>>[0];

export function getOrderType(order: ParsedManyResponseOrder) {
  const orderTypeAttr = (order?.attributes || []).find((a) => a.type === CommonAttributeTypeCode.OrderType);
  return orderTypeAttr?.code === OrderTypeAttributeCode.Buy ? OrderType.Buy : OrderType.Sell;
}

export function getOrderStatus(order: ParsedManyResponseOrder) {
  let statuses = (order?.attributes || [])
    .filter((a) => a.type === CommonAttributeTypeCode.OrderStatus)
    .map((attr) => attributeCodeToOrderStatusMapping[attr?.code as OrderStatusAttributeCode])
    .filter(Boolean) as OrderStatus[];

  if (statuses.length > 1 && statuses.includes(OrderStatus.Active)) {
    statuses = statuses.filter((s) => s !== OrderStatus.Active);
  }

  return statuses[0] || OrderStatus.Canceled;
}

export function getOrderTradeStatus(order: ParsedManyResponseOrder) {
  const statuses = (order?.attributes || [])
    .filter((a) => a.type === CommonAttributeTypeCode.TradeStatus)
    .map((attr) => tradeStatusAttributeCodeToOrderStatusMapping[attr?.code as TradeStatusAttributeCode])
    .filter(Boolean) as OrderStatus[];

  return statuses[0] || OrderStatus.Canceled;
}

export function getOrderCatalogs(order: ParsedManyResponseOrder) {
  let category: ParsedCatalog | undefined;
  let product: ParsedCatalog | undefined;
  let variety: ParsedCatalog | undefined;

  const varietyProp = (order?.productProperties || []).find((p) => p.type === ProductPropertyTypeCode.Variety);
  const productProp = (order?.productProperties || []).find((p) => p.type === ProductPropertyTypeCode.Product);

  order.catalogs.forEach((catalog) => {
    if (catalog.id === varietyProp?.value) {
      variety = catalog;
    } else if (catalog.id === productProp?.value) {
      product = catalog;
    } else {
      category = catalog;
    }
  });

  return {
    category,
    product,
    variety,
  };
}

export function getOrderChars(order: ParsedManyResponseOrder) {
  const unitPrice = +(order?.data?.unitsPrice || 0);
  const unitAmount = +(order?.data?.unitsAmount || 0);
  const latestDate = order?.data?.deliveryDate || null;
  const town = order?.data?.deliveryLocation || null;

  const totalPrice = unitPrice * unitAmount;

  return {
    unitPrice,
    unitAmount,
    latestDate,
    town,
    totalPrice,
  };
}

export function getOrderUnitType(order: ParsedManyResponseOrder) {
  const unitTypeAttr = (order?.attributes || []).find((p) => p.type === CommonAttributeTypeCode.Units);
  return attributeCodeToUnitTypeMapping[unitTypeAttr?.code as UnitAttributeCode] || OrderUnitsType.Weight;
}

export function getOrderDeliveryChars(order: ParsedManyResponseOrder) {
  const orderType = getOrderType(order);

  const deliveryAttr = (order?.attributes || []).find((p) => p.type === CommonAttributeTypeCode.Delivery);

  let deliveryType: OrderDeliveryType;

  if (order.data.productType === AimeosProductType.Trade) {
    if (orderType === OrderType.Buy) {
      deliveryType =
        deliveryAttr?.code === DeliveryAttributeCode.From ? OrderDeliveryType.NeedPickup : OrderDeliveryType.CanDeliver;
    } else {
      deliveryType =
        deliveryAttr?.code === DeliveryAttributeCode.From
          ? OrderDeliveryType.NeedDelivery
          : OrderDeliveryType.CanPickup;
    }
  } else {
    if (orderType === OrderType.Buy) {
      deliveryType =
        deliveryAttr?.code === DeliveryAttributeCode.From
          ? OrderDeliveryType.NeedDelivery
          : OrderDeliveryType.CanPickup;
    } else {
      deliveryType =
        deliveryAttr?.code === DeliveryAttributeCode.From ? OrderDeliveryType.NeedPickup : OrderDeliveryType.CanDeliver;
    }
  }

  return { deliveryAttr, deliveryType };
}

export function getOrderProvinces(order: ParsedManyResponseOrder) {
  return (order?.attributes || []).filter((p) => p.type === CommonAttributeTypeCode.Province);
}

export function getOrderCustomAttrs(order: ParsedManyResponseOrder) {
  return (order?.attributes || []).filter((p) => p.type.startsWith('exchange-attribute-type-class'));
}

export function getOrderOwner(order: ParsedManyResponseOrder) {
  return order?.productProperties?.find((p) => p.type === ProductPropertyTypeCode.Creator)?.value || '';
}

export function getOrderTradeOwner(order: ParsedManyResponseOrder) {
  return order?.productProperties?.find((p) => p.type === ProductPropertyTypeCode.TradeCreator)?.value || '';
}

export function parseOrder(order: ParsedManyResponseOrder) {
  const { id, code, label, createdAt, canBeReviewed } = order.data;

  const orderType = getOrderType(order);

  const status = getOrderStatus(order);

  const tradeStatus = getOrderTradeStatus(order);

  const catalogs = getOrderCatalogs(order);

  const chars = getOrderChars(order);

  const unitType = getOrderUnitType(order);

  const deliveryChars = getOrderDeliveryChars(order);

  const provinces = getOrderProvinces(order);

  const customAttrs = getOrderCustomAttrs(order);

  const owner = getOrderOwner(order);

  const tradeOwner = getOrderTradeOwner(order);

  return {
    id,
    code: code.includes('-trade') ? code.split('-trade')?.[0] || code : code,
    label,
    createdAt,
    orderType,
    status,
    tradeStatus,
    ...catalogs,
    ...chars,
    unitType,
    ...deliveryChars,
    provinces,
    customAttrs,
    owner,
    tradeOwner,
    canBeReviewed,
  };
}

export type ParsedOrder = ReturnType<typeof parseOrder>;
