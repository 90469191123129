import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import axiosInstance from 'api/config/axios';
import { ProductPropertyTypeCode } from 'utils/enums';
import { memoryQueryClient } from 'providers/TanstackQuery';
import { useDataValues } from 'providers/DataValues';
import { getHiddenPendingOrders } from 'utils/session';
import { AimeosEntityType, AimeosManyResponse, AimeosProduct } from '../../types';
import { parseManyResponse } from '../../parsers';

type Params = {
  userId: string;
  pendingAttrId: string;
};

async function getMyPendingOrders({ userId, pendingAttrId }: Params) {
  const hiddenPendingOrders = getHiddenPendingOrders();

  const params = {
    page: {
      limit: 10,
      offset: 0,
    },
    sort: 'product.mtime',
    fields: {
      product: 'product.id,product.mtime,product.code',
    },
    filter: {
      '&&': [
        { '==': { 'product.status': 1 } },
        { '!=': { [`product:prop("${ProductPropertyTypeCode.Creator}",null,"${userId}")`]: userId } },
        { '!=': { [`product:has("attribute","default","${pendingAttrId}")`]: pendingAttrId } },
        ...hiddenPendingOrders.map((hiddenOrderId) => ({
          '!=': { 'product.id': hiddenOrderId },
        })),
      ],
    },
  };

  const response = await axiosInstance.query.get<AimeosManyResponse<AimeosProduct>>('/admin/default/jsonadm/product', {
    params,
  });

  return response.data;
}

const selector = (result: AimeosManyResponse<AimeosProduct>) => {
  return parseManyResponse<AimeosEntityType.Product>(result);
};

export const getQueryKey = (params: Params) =>
  ['trade', 'pending-orders', params.userId, params.pendingAttrId] as const;

export function useGetMyPendingOrders(enabled = true) {
  const { userId, appValues } = useDataValues();

  const params = useMemo(
    () => ({ userId, pendingAttrId: appValues?.attrMapping.orderStatus.Pending?.id || '' }),
    [userId, appValues],
  );

  return useQuery(
    {
      queryKey: getQueryKey(params),
      queryFn: () => getMyPendingOrders(params),
      enabled: !!params.userId && !!params.pendingAttrId && enabled,
      select: selector,
    },
    memoryQueryClient,
  );
}
