import { useMemo } from 'react';
import dayjs from 'dayjs';
import { GetMyProfileResponse } from 'api/queries/customer/me.types';
import { FROZEN_CHANNEL_STATUS } from 'utils/constants';
import { ChatMembership, ChatReducerState, ProcessedChatReducerState } from '../reducer/types';

function preprocessMembership(membership: ChatMembership, profile?: GetMyProfileResponse): ChatMembership {
  let isUnread = false;
  let isNew = !membership.lastMessage;

  if (membership.custom.status === FROZEN_CHANNEL_STATUS) {
    isUnread = false;
    isNew = false;
  } else {
    if (membership.lastMessage?.uuid === profile?.chat_id) {
      isUnread = false;
    } else {
      const { lastReadMessageTimestamp, lastMessage } = membership;
      if (!lastReadMessageTimestamp) {
        isUnread = true;
      } else if (lastMessage?.timetoken) {
        isUnread = lastReadMessageTimestamp < lastMessage.timetoken;
      }
    }
  }

  if (isNew) {
    isUnread = false;
  }

  return {
    ...membership,
    isUnread,
    isNew,
  };
}

export default function useChatStatePreprocessor(state: ChatReducerState, profile?: GetMyProfileResponse) {
  return useMemo<ProcessedChatReducerState>(() => {
    const memberships = state.memberships.map((membership) => preprocessMembership(membership, profile));

    return {
      memberships: memberships
        .sort((a, b) => {
          try {
            const time1 = dayjs(a.updatedAt).toDate().getTime();
            const time2 = dayjs(b.updatedAt).toDate().getTime();
            return time2 - time1;
          } catch (err) {
            console.error(err);
          }
          return 0;
        })
        .sort((a, b) => {
          try {
            const unread1 = +!!a.isUnread;
            const unread2 = +!!b.isUnread;
            return unread2 - unread1;
          } catch (err) {
            console.error(err);
          }
          return 0;
        }),
      chatId: state.chatId,
      hasUnreadMessages: memberships.some((m) => m.isUnread),
    };
  }, [state, profile]);
}
