import dayjs from 'dayjs';
import clsx from 'clsx';
import SectionSeparator from 'pages/client/Orders/Make/components/SectionSeparator';
import { previewGroupGridClsx } from 'pages/client/Orders/Make/classnames';
import FileRoundedIcon from 'assets/svg/FileRounded';
import BoxRoundedIcon from 'assets/svg/BoxRounded';

import { OrderDeliveryType, OrderUnitsType, publicDeliveryTypeTraderFullLabels } from 'utils/enums';
import { priceFormatter, unitsFormatter } from 'utils/formatters';
import { useDataValues } from 'providers/DataValues';
import FieldBlock from 'components/preview/FieldBlock';
import { ParsedOrder } from 'api/order.parsers';
import { orderHasExpiredDate } from 'utils/order';
import { DISPLAY_DATE_TEMPLATE } from 'utils/constants';

export default function MakeOrderPreview(props: ParsedOrder) {
  const { appValues, loading } = useDataValues();

  const orderIsExpired = orderHasExpiredDate(props.tradeStatus, props.latestDate);

  return (
    <>
      <SectionSeparator Icon={FileRoundedIcon}>General Information</SectionSeparator>

      <div className={'h-5 md:h-8'} />

      <section className={'flex flex-col gap-4'}>
        <div className={previewGroupGridClsx}>
          <FieldBlock label={'Category'}>{props.category?.label}</FieldBlock>
          <FieldBlock label={'Product'}>{props.product?.label}</FieldBlock>
          <FieldBlock label={'Variety'}>{props.variety?.label}</FieldBlock>
        </div>

        {!!props.customAttrs.length && (
          <div className={previewGroupGridClsx}>
            {props.customAttrs
              .sort((a, b) => a.type.localeCompare(b.type))
              .map((customField) => (
                <FieldBlock
                  key={customField.id}
                  label={appValues?.attrTypes.find((at) => at.code === customField.type)?.label || ''}
                  loading={loading}
                >
                  {customField.label}
                </FieldBlock>
              ))}
          </div>
        )}

        <div className={previewGroupGridClsx}>
          <FieldBlock label={'Type'}>{props.unitType === OrderUnitsType.Weight ? 'Weight' : 'Pieces'}</FieldBlock>

          <FieldBlock label={props.unitType === OrderUnitsType.Weight ? 'Weight (kg)' : 'Number of Pieces'}>
            {unitsFormatter.format(props.unitAmount)}
          </FieldBlock>

          <FieldBlock label={props.unitType === OrderUnitsType.Weight ? 'Price/kg (PHP)' : 'Price/piece (PHP)'}>
            {priceFormatter.format(props.unitPrice)}
          </FieldBlock>

          <FieldBlock label={'Total price (PHP)'}>
            {priceFormatter.format(props.unitAmount * props.unitPrice)}
          </FieldBlock>
        </div>
      </section>

      <div className={'h-8 md:h-12'} />

      <SectionSeparator Icon={BoxRoundedIcon}>Delivery</SectionSeparator>

      <div className={'h-5 md:h-8'} />

      <section className={'flex flex-col gap-4'}>
        <div className={clsx(previewGroupGridClsx, orderIsExpired && 'bg-red-500/10')}>
          <FieldBlock label={'Latest delivery date'}>
            {dayjs(props.latestDate).format(DISPLAY_DATE_TEMPLATE)}
          </FieldBlock>

          {orderIsExpired && (
            <p
              className={
                'rounded-[40px] bg-[#FFD7D7] py-2.5 px-5 text-brand-red-main text-sm/tight font-semibold text-center self-center'
              }
            >
              This trade is expired
            </p>
          )}
        </div>

        <div className={previewGroupGridClsx}>
          <FieldBlock label={'Type'}>{publicDeliveryTypeTraderFullLabels[props.deliveryType]}</FieldBlock>

          {[OrderDeliveryType.NeedPickup, OrderDeliveryType.NeedDelivery].includes(props.deliveryType) && (
            <>
              <FieldBlock label={'Province'}>{props.provinces?.[0]?.label}</FieldBlock>
              <FieldBlock label={'Town'}>{props.town || 'Unknown'}</FieldBlock>
            </>
          )}

          {[OrderDeliveryType.CanDeliver, OrderDeliveryType.CanPickup].includes(props.deliveryType) && (
            <FieldBlock label={'Provinces'}>{props.provinces?.map((v) => v.label).join(', ')}</FieldBlock>
          )}
        </div>
      </section>
    </>
  );
}
