import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Button } from 'flowbite-react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router';
import { useIsTabletAndLess } from 'hooks/media-query';
import { useDataValues } from 'providers/DataValues';
import { finishOnboarding } from 'api/mutations/auth/onboard';
import { cachedQueryClient } from 'providers/TanstackQuery';
import { getMyProfileQueryKey } from 'api/queries/customer/me';
import { GetMyProfileResponse } from 'api/queries/customer/me.types';
import { OnboardingManagerTooltips, OnboardingStep } from './config';

export default function OnboardingManager() {
  const { profile } = useDataValues();

  const [isShown, setIsShown] = useState(false);
  const [step, setStep] = useState(OnboardingStep.Dashboard);
  const navigate = useNavigate();
  const isTabletAndLess = useIsTabletAndLess();

  const { desktop, mobile, title, description, path } = OnboardingManagerTooltips[step] || {};

  const { elementId, tooltipId } = (isTabletAndLess ? mobile : desktop) || {};

  useEffect(() => {
    if (isShown && elementId) {
      navigate(path, { replace: true });
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [isShown, elementId]);

  useEffect(() => {
    if (typeof profile?.onboarded === 'boolean' && !profile.onboarded) {
      const timeout = setTimeout(() => setIsShown(true), 1500);

      return () => clearTimeout(timeout);
    }
  }, [profile]);

  if (isShown) {
    const closeOnboarding = () => {
      setIsShown(false);
      finishOnboarding()
        .catch(console.error)
        .finally(() => {
          cachedQueryClient.setQueryData(getMyProfileQueryKey(), (profile: GetMyProfileResponse) => {
            if (profile) {
              return {
                ...profile,
                onboarded: true,
              };
            }
            return profile;
          });
          navigate('/', { replace: true });
        });
    };

    return (
      <div className={'fixed top-0 bottom-0 left-0 right-0 z-50 bg-brand-blue-dark/40'}>
        {ReactDOM.createPortal(
          <Tooltip
            clickable
            isOpen
            variant="light"
            id={tooltipId}
            place={'bottom'}
            style={{ padding: '1.125rem 1rem', borderRadius: '20px' }}
            className={'bg-white z-50 max-w-[312px] md:max-w-[380px] flex flex-col gap-6'}
            classNameArrow={'react-tooltip-arrow !w-8 !h-8 z-[-1]'}
            offset={20}
            opacity={1}
          >
            <h4 className={'text-center font-black text-brand-black'}>{title}</h4>

            <p className={'text-center text-balance text-sm text-brand-purple-gray'}>{description}</p>

            <div className={'flex justify-center items-center gap-6'}>
              {step !== OnboardingStep.SellOrderMarketplace ? (
                <>
                  <Button
                    color={'gray'}
                    pill
                    onClick={closeOnboarding}
                    className={'min-w-[72px] bg-brand-gray-soft font-semibold md:min-w-[152px] h-[44px]'}
                  >
                    Close
                  </Button>

                  <Button
                    color={'dark'}
                    pill
                    onClick={() => setStep((prev) => prev + 1)}
                    className={'min-w-[72px] font-semibold md:min-w-[152px] h-[44px]'}
                  >
                    Next Tooltip
                  </Button>
                </>
              ) : (
                <Button color={'dark'} pill onClick={closeOnboarding} className={'w-full font-semibold'}>
                  Got it, thanks!
                </Button>
              )}
            </div>
          </Tooltip>,
          document.body,
        )}
      </div>
    );
  }

  return null;
}
