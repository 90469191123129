import { NotificationMenuItem } from './NotificationItem';

const NotificationMenuMobileLayout = () => {
  return (
    <div className="flex flex-col justify-between items-center px-4 h-full">
      <span className="flex w-full items-center text-xl font-black px-2 h-[60px]">Notifications</span>
      <div className="flex flex-col w-full">
        <NotificationMenuItem />
        <NotificationMenuItem />
        <NotificationMenuItem />
      </div>

      <button className="rounded-lg bg-brand-purple-gray w-[90px] text-white text-xs py-1">Clear All</button>
    </div>
  );
};

export default NotificationMenuMobileLayout;
