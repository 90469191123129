import { useCallback } from 'react';
import Pubnub from 'pubnub';
import { MAX_CHAT_MEMBERSHIP_FETCH_COUNT } from 'utils/constants';

export default function useLoadMemberships(chatClient?: Pubnub) {
  return useCallback(
    async (nextCursor?: string) => {
      if (chatClient) {
        const memberships = await chatClient.objects.getMemberships({
          filter: "channel.name LIKE 'Order*'",
          include: { customFields: true, customChannelFields: true, channelStatusField: true, totalCount: true },
          ...(nextCursor ? { page: { next: nextCursor } } : {}),
          limit: MAX_CHAT_MEMBERSHIP_FETCH_COUNT,
        });

        const messagesPerChannel = await chatClient.fetchMessages({
          channels: memberships.data.map((d) => d.channel.id),
          //We need to load only last message
          count: 1,
          includeUUID: true,
        });

        return [memberships, messagesPerChannel] as const;
      }
      return null;
    },
    [chatClient],
  );
}
