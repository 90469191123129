export default function BoxRoundedIcon({ size = 26, color: _1, filled: _2, ...props }: IconProps) {
  return (
    <svg width={size} height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="13" r="13" fill="#272733" />
      <g>
        <path
          d="M19.6895 9.46285C19.6895 9.44805 19.6895 9.44805 19.6895 9.43325C19.6747 9.40366 19.6747 9.37406 19.6599 9.34446V9.32967C19.6451 9.30007 19.6155 9.27047 19.6007 9.25567L19.5859 9.24087C19.5711 9.22608 19.5415 9.21128 19.5267 9.19648L19.5119 9.18168H19.4971L19.4824 9.16688L13.193 6.0444C13.0746 5.9852 12.9266 5.9852 12.7934 6.0444L10.6772 7.09509L16.9962 10.336L17.011 10.3508C17.0258 10.3508 17.0258 10.3656 17.0406 10.3656C17.0554 10.3804 17.0554 10.3952 17.0702 10.41C17.0702 10.4248 17.0702 10.4248 17.0702 10.4396V10.4544V13.8876C17.0702 13.9468 17.0406 13.9912 16.9962 14.0208L15.7235 14.6867C15.6495 14.7311 15.5607 14.7015 15.5163 14.6275C15.5016 14.6127 15.5015 14.5831 15.5015 14.5535V11.1943L9.10859 7.89421L9.09379 7.87941L6.53365 9.15208L6.51885 9.16688H6.50405L6.48926 9.18168C6.47446 9.19648 6.44486 9.21128 6.43006 9.22608L6.41526 9.24087C6.38567 9.27047 6.37087 9.30007 6.34127 9.32967V9.34446C6.32647 9.37406 6.31167 9.40366 6.31167 9.43325C6.31167 9.44805 6.31167 9.44805 6.31167 9.46285C6.31167 9.49245 6.29688 9.50725 6.29688 9.53684V9.55164V16.4478C6.29688 16.6105 6.38567 16.7733 6.54845 16.8473L12.7786 19.955C12.8674 19.9994 12.971 20.0142 13.0746 19.9846L13.1042 19.9698C13.1338 19.9698 13.1486 19.955 13.1782 19.9402L19.4528 16.8325C19.6007 16.7585 19.7043 16.6105 19.7043 16.433V9.55164V9.53684C19.6895 9.50725 19.6895 9.49245 19.6895 9.46285Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
