import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';

export async function deleteProfile() {
  await axiosClient.mutate.delete(`/api/v1/user`);
  return true;
}

const mutationKey = ['delete-profile'] as const;

export function useDeleteProfileMutation() {
  return useMutation({
    mutationKey,
    mutationFn: deleteProfile,
  });
}
