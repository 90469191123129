import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import TanstackQueryProvider from 'providers/TanstackQuery';
import AppStateProvider from 'providers/AppState';

import AppRouter from './navigation/AppRouter';
import { NOTISTACK_OPTIONS } from './utils/constants';

const helmetContext = {};

export default function AppWithProviders() {
  return (
    <SnackbarProvider {...NOTISTACK_OPTIONS}>
      <TanstackQueryProvider>
        <AppStateProvider>
          <HelmetProvider context={helmetContext}>
            <AppRouter />
          </HelmetProvider>
        </AppStateProvider>
      </TanstackQueryProvider>
    </SnackbarProvider>
  );
}
