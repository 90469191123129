import dayjs from 'dayjs';
import { OrderType } from 'utils/enums';
import ReviewStars from 'components/ReviewStars';
import { ParsedReviews } from 'api/queries/review/review';
import { DISPLAY_DATE_TEMPLATE } from 'utils/constants';

type Props = {
  review: ParsedReviews[0]['data'];
};

export default function ReviewItem({ review }: Props) {
  return (
    <div className="flex flex-col bg-brand-purple-card rounded-lg p-4 justify-between">
      {!!review.comment && <p className="text-xs font-normal pb-2 mb-4">{review.comment}</p>}
      <div className="w-full flex flex-col justify-between md:hidden gap-1">
        <div className="flex flex-row justify-between items-center">
          <p className="text-xs font-normal text-brand-purple-gray">
            {dayjs(review.createdAt).format(DISPLAY_DATE_TEMPLATE)}
          </p>
          <p className="text-xs font-normal text-brand-purple-gray">
            <span className="font-bold">User </span>
            {review.productCustomerUsername}
          </p>
        </div>
        <div className="flex flex-row justify-between items-center pt-1">
          <ReviewStars rating={review.rating} />
          <p className="text-xs font-normal text-brand-purple-gray">{`${
            review.productType === OrderType.Buy ? 'Buy' : 'Sell'
          } Order ${review.productCode}`}</p>
        </div>
      </div>
      <div className="w-full hidden md:flex flex-row justify-between items-center">
        <ReviewStars rating={review.rating} />
        <p className="text-xs font-normal text-brand-purple-gray">
          {dayjs(review.createdAt).format(DISPLAY_DATE_TEMPLATE)}
        </p>
        <p className="text-xs font-normal text-brand-purple-gray">
          <span className="font-bold">User </span>
          {review.productCustomerUsername}
        </p>
        <p className="text-xs font-normal text-brand-purple-gray">{`${
          review.productType === OrderType.Buy ? 'Buy' : 'Sell'
        } Order ${review.productCode}`}</p>
      </div>
    </div>
  );
}
