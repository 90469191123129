import { useDeleteProfileMutation } from 'api/mutations/auth/delete-profile';
import { useGetActiveProductsAmountQuery } from 'api/queries/product/active-products-amount';
import { useGetActiveTradesAmountQuery } from 'api/queries/product/active-trades-amount';
import FormFieldBlock from 'components/form/Block';
import TextArea from 'components/form/TextArea';
import { useAppState } from 'providers/AppState';
import { useDataValues } from 'providers/DataValues';
import { useModals } from 'providers/Modals';
import { KEYS, logEvent } from 'utils/analytics';
import { appLogout } from 'utils/session';

export default function PersonalInfo() {
  const { profile } = useDataValues();
  const { open: openModal } = useModals();
  const { logout } = useAppState();
  const { refetch: fetchActiveOrdersCount } = useGetActiveProductsAmountQuery();
  const { refetch: fetchActiveTradesCount } = useGetActiveTradesAmountQuery();
  const { mutateAsync: deleteProfile } = useDeleteProfileMutation();

  const onResetPassword = () => {
    openModal('cancel-confirm', {
      titleElement: (
        <>
          <b>Are you sure you want</b>
          <br />
          {'to Reset Password?'}
        </>
      ),
      cancel: { label: 'Cancel' },
      image: 'manAndLock',
      confirm: {
        label: 'Yes, Reset',
        asyncOnClick: async (closeModal) => {
          const searchParams = new URLSearchParams();
          searchParams.set('email', profile.email || '');
          logout(`/forgot-password?${searchParams.toString()}`);
          logEvent(KEYS.reset_password);
          closeModal();
        },
      },
    });
  };

  const onDeleteProfile = async () => {
    // Active, Pending and Traded orders
    const activeOrders = await fetchActiveOrdersCount();
    // Traded and Pending trades
    const activeTrades = await fetchActiveTradesCount();
    if (activeOrders.data || activeTrades.data) {
      openModal('cancel-confirm', {
        title: "Your account can't be deleted now",
        descriptionElement: (
          <p className="text-sm text-center text-brand-black">
            {`Please make sure you don't have any active orders or trades (orders with statuses "Active", "Pending" and "Traded").`}
          </p>
        ),
        image: 'manAndNotify',
        cancel: {
          label: 'Got it',
        },
      });
    } else {
      openModal('cancel-confirm', {
        titleElement: (
          <>
            <b>Are you sure you want</b>
            <br />
            {'to Delete your Profile?'}
          </>
        ),
        description: "This action can't be undone",
        cancel: { label: 'Cancel' },
        image: 'workerAndQuestion',
        confirm: {
          label: 'Yes, Delete',
          asyncOnClick: async (closeModal) => {
            await deleteProfile();
            logEvent(KEYS.account_deleted);
            appLogout();
            closeModal();
          },
        },
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-between px-6 lg:px-0 lg:pl-10">
      <div className="flex flex-col w-full md:w-[260px]">
        <FormFieldBlock label="Email">
          <TextArea placeholder={profile.email} rows={1} className={'resize-none'} disabled />
        </FormFieldBlock>
        <div className="h-2" />
        <FormFieldBlock label="Phone">
          <TextArea placeholder={profile.telephone || '+63'} rows={1} className={'resize-none'} disabled />
        </FormFieldBlock>
        <div className="h-6" />
        <button
          onClick={onResetPassword}
          className="flex flex-row justify-center self-start rounded-[40px] items-center text-xs text-white font-semibold whitespace-nowrap h-6 px-4 bg-brand-black"
        >
          Reset password
        </button>
      </div>
      <button
        onClick={onDeleteProfile}
        className="flex flex-row justify-center rounded-[40px] w-[130px] items-center text-xs text-white font-semibold whitespace-nowrap h-6 px-4 mb-10 bg-brand-red-main"
      >
        Delete profile
      </button>
    </div>
  );
}
