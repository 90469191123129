import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../config/axios';

export async function getUserDetails(userId: string) {
  const response = await axiosInstance.query.get<{
    success: boolean;
    review_avg: string;
    review_count: number;
    username: string;
  }>(`/api/v1/user/${userId}`);

  return {
    id: userId,
    username: response.data.username,
    rating: Math.max(parseFloat(response.data.review_avg || '0'), 0),
    reviewsCount: response.data.review_count || 0,
  };
}

export const getQueryKey = (userId: string) => ['customer', 'get-one', userId] as const;

export function useGetUserDetailsQuery(userId: string, cached = true, enabled = true) {
  return useQuery({
    queryKey: getQueryKey(userId),
    queryFn: () => getUserDetails(userId),
    enabled: !!userId && enabled,
    refetchOnWindowFocus: false,
    ...(cached
      ? {
          networkMode: 'offlineFirst',
          staleTime: 1000 * 15, // 15 seconds
        }
      : {}),
  });
}
