import { useEffect, useState } from 'react';
import { useGetMyPendingOrders } from 'api/queries/trades/get-pending-orders';
import { useModals } from 'providers/Modals';
import { useAppState } from 'providers/AppState';

const CHECK_INTERVAL = 15000; //ms

export default function useWatchMyPendingOrders() {
  const { open, openedModal } = useModals();
  const [trigger, setTrigger] = useState(false);

  const { blockRequests } = useAppState();

  const { data, refetch } = useGetMyPendingOrders(openedModal !== 'accept-deny-trade');

  useEffect(() => {
    let timeout: any = null;

    if (!data || blockRequests) {
      return () => clearTimeout(timeout);
    }

    refetch()
      .then(({ data: result }) => {
        const pendingOrder = result?.[0]?.data;
        if (pendingOrder) {
          open('accept-deny-trade', { id: pendingOrder.id, code: pendingOrder.code }, false);
        }
      })
      .catch(console.error)
      .finally(() => {
        timeout = setTimeout(() => {
          setTrigger(!trigger);
        }, CHECK_INTERVAL);
      });

    return () => clearTimeout(timeout);
  }, [data, trigger, blockRequests, refetch, open]);
}
