export default function HamburgerIcon({ size = 26, color = 'currentColor', filled: _1, ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="13" r="13" fill={color} />
      <rect x="7.64746" y="8.41162" width="10.7059" height="1.52941" rx="0.764706" fill="#272733" />
      <rect x="7.64746" y="12.2354" width="10.7059" height="1.52941" rx="0.764706" fill="#272733" />
      <rect x="7.64746" y="16.0586" width="10.7059" height="1.52941" rx="0.764707" fill="#272733" />
    </svg>
  );
}
