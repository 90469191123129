import { lazy } from 'react';

const modals = {
  'accept-deny-trade': lazy(() => import('./AcceptDenyTrade')),
  'buy-sell-order-prompt': lazy(() => import('./BuySellOrderPrompt')),
  'cancel-confirm': lazy(() => import('./CancelConfirm')),
  'leave-review': lazy(() => import('./LeaveReview')),
  'prompt-confirm': lazy(() => import('./PromptConfirm')),
  'report-problem': lazy(() => import('./ReportProblem')),
  'report-issue': lazy(() => import('./ReportIssue')),
  'set-alert': lazy(() => import('./SetAlert')),
};

export type ModalName = keyof typeof modals;

export type ModalProps<T extends ModalName> = Parameters<(typeof modals)[T]>[0];

export default modals;
