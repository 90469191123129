import clsx from 'clsx';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  route: string;
  icon: React.ElementType<{
    size?: number;
    isActive?: boolean;
  }>;
  isActive?: boolean;
  counter?: number;
  onClick?: () => void;
  onboarding?: { elementId: string; tooltipId: string };
};

export default function TabBarItem({ title, route, icon: Icon, isActive, counter, onClick, onboarding }: Props) {
  return (
    <Link
      id={onboarding?.elementId}
      data-tooltip-id={onboarding?.tooltipId}
      to={route}
      onClick={onClick}
      className={'h-16 flex flex-1 flex-col justify-between items-center text-center  pt-3 '}
    >
      <div className="relative w-[30px] h-[30px] flex justify-center items-center">
        <Icon isActive={isActive} />
        <div
          className={clsx(
            'top-1 right-1 h-[12px] w-[12px] rounded-full flex items-center justify-center text-white text-xxxs font-black',
            counter ? 'absolute' : 'hidden',
            isActive ? 'bg-brand-black' : 'bg-brand-red-main',
          )}
          style={{ transform: 'translate(50%, -50%)' }}
        >
          <span>{counter}</span>
        </div>
      </div>
      <span
        className={clsx(
          'text-xxs font-semibold break-words',
          isActive ? 'text-brand-black' : '  text-brand-purple-gray',
        )}
      >
        {title}
      </span>
    </Link>
  );
}
