import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/config/axios';
import {
  CommonAttributeTypeCode,
  DeliveryAttributeCode,
  OrderDeliveryType,
  OrderStatus,
  OrderStatusAttributeCode,
  OrderType,
  OrderTypeAttributeCode,
  OrderUnitsType,
  TradeStatusAttributeCode,
  UnitAttributeCode,
} from 'utils/enums';
import { GetAllAppValuesResponse } from './all-values.types';
import { ParsedAttribute, ParsedAttributeType, ParsedCatalog } from '../../parsers';
import { AimeosEntityType } from '../../types';

async function getAllAppValues() {
  const catalogFilter = {
    '&&': [{ '<': { 'catalog.level': 4 } }, { '>=': { 'catalog.status': '-3' } }],
  };

  const orderAttrFilter = {
    '&&': [
      { '==': { 'attribute.status': 1 } },
      { '~=': { 'attribute.code': 'exchange-attribute' } },
      { '!': [{ '~=': { 'attribute.code': 'exchange-attribute-class' } }] },
    ],
  };

  const customAttrFilter = {
    '~=': { 'attribute.code': 'exchange-attribute-class' },
  };

  const customAttrTypesFilter = {
    '~=': { 'attribute.type.code': 'exchange-attribute-type' },
  };

  const stringify = (value: any) => JSON.stringify(JSON.stringify(value));

  const response = await axiosInstance.mutate.post<GetAllAppValuesResponse>(`/admin/default/graphql`, {
    query: `
        query {
            catalogs: searchCatalogs(limit: 9999, offset: 0, include: ["attribute"],sort:"catalog.label",filter: ${stringify(
              catalogFilter,
            )}) {
              id
              status
              level
              label
              parentid
              lists {
                attribute {
                  id
                  refid
                  parentid
                }
              }
            }
            orderAttrs: searchAttributes(limit: 9999, offset: 0, sort: "attribute.label", filter:${stringify(
              orderAttrFilter,
            )}) {
              id
              code
              label
              position
              type
            }
            customAttrs: searchAttributes(limit: 9999, offset: 0, sort: "attribute.position", filter:${stringify(
              customAttrFilter,
            )}) {
              id
              code
              label
              position
              type
            }
            attrTypes: searchAttributeTypes(limit: 999, offset: 0, filter: ${stringify(customAttrTypesFilter)}) {
              id
              code
              label
            }
        }
    `,
  });

  const _res = response.data as any;

  if (_res?.errors) {
    console.error(_res?.errors);
    throw new Error(_res?.errors?.[0]);
  }

  return response.data;
}

export const getAllValuesQueryKey = () => ['common', 'all-app-values'] as const;

const selector = (result: GetAllAppValuesResponse) => {
  const attributes = {
    orderTypes: [] as ParsedAttribute[],
    orderStatus: [] as ParsedAttribute[],
    tradeStatus: [] as ParsedAttribute[],
    units: [] as ParsedAttribute[],
    deliveryTypes: [] as ParsedAttribute[],
    provinces: [] as ParsedAttribute[],
  };

  result.data.orderAttrs.forEach((attribute) => {
    let key: keyof typeof attributes = 'orderTypes';
    if (attribute.type === CommonAttributeTypeCode.Units) {
      key = 'units';
    } else if (attribute.type === CommonAttributeTypeCode.OrderStatus) {
      key = 'orderStatus';
    } else if (attribute.type === CommonAttributeTypeCode.TradeStatus) {
      key = 'tradeStatus';
    } else if (attribute.type === CommonAttributeTypeCode.Delivery) {
      key = 'deliveryTypes';
    } else if (attribute.type === CommonAttributeTypeCode.Province) {
      key = 'provinces';
    }

    attributes[key].push({
      id: attribute.id,
      entityType: AimeosEntityType.Attribute,
      type: attribute.type,
      code: attribute.code,
      label: attribute.label,
    });
  });

  const attrMapping = {
    orderType: {
      [OrderType.Buy]: attributes.orderTypes.find((a) => a.code === OrderTypeAttributeCode.Buy),
      [OrderType.Sell]: attributes.orderTypes.find((a) => a.code === OrderTypeAttributeCode.Sell),
    },
    orderStatus: {
      [OrderStatus.Active]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Active),
      [OrderStatus.Canceled]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Canceled),
      [OrderStatus.Editing]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Editing),
      [OrderStatus.Completed]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Completed),
      [OrderStatus.Pending]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Pending),
      [OrderStatus.Replaced]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Replaced),
      [OrderStatus.Traded]: attributes.orderStatus.find((a) => a.code === OrderStatusAttributeCode.Traded),
    },
    tradeStatus: {
      [TradeStatusAttributeCode.Canceled]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Canceled,
      ),
      [TradeStatusAttributeCode.Editing]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Editing,
      ),
      [TradeStatusAttributeCode.Completed]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Completed,
      ),
      [TradeStatusAttributeCode.Pending]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Pending,
      ),
      [TradeStatusAttributeCode.Replaced]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Replaced,
      ),
      [TradeStatusAttributeCode.Traded]: attributes.tradeStatus.find((a) => a.code === TradeStatusAttributeCode.Traded),
      [TradeStatusAttributeCode.Declined]: attributes.tradeStatus.find(
        (a) => a.code === TradeStatusAttributeCode.Declined,
      ),
    },
    units: {
      [OrderUnitsType.Pieces]: attributes.units.find((a) => a.code === UnitAttributeCode.Pieces),
      [OrderUnitsType.Weight]: attributes.units.find((a) => a.code === UnitAttributeCode.Weight),
    },
    delivery: {
      [OrderDeliveryType.NeedDelivery]: attributes.deliveryTypes.find((a) => a.code === DeliveryAttributeCode.From),
      [OrderDeliveryType.NeedPickup]: attributes.deliveryTypes.find((a) => a.code === DeliveryAttributeCode.From),
      [OrderDeliveryType.CanPickup]: attributes.deliveryTypes.find((a) => a.code === DeliveryAttributeCode.To),
      [OrderDeliveryType.CanDeliver]: attributes.deliveryTypes.find((a) => a.code === DeliveryAttributeCode.To),
    },
  };

  //

  const classAttrs = result.data.customAttrs.map<ParsedAttribute>((attr) => ({
    id: attr.id,
    entityType: AimeosEntityType.Attribute,
    type: attr.type,
    code: attr.code,
    label: attr.label,
  }));

  const attrIDMapping = new Map<string, ParsedAttribute>();

  classAttrs.forEach((attr) => attrIDMapping.set(attr.id, attr));
  attributes.units.forEach((attr) => attrIDMapping.set(attr.id, attr));

  //

  const attrTypes = result.data.attrTypes.map<ParsedAttributeType>((t) => ({
    id: t.id,
    entityType: AimeosEntityType.AttributeType,
    code: t.code,
    label: t.label,
    name: t.label,
  }));

  //

  const catalogs = {
    categories: [] as ParsedCatalog[],
    products: [] as ParsedCatalog[],
    varieties: [] as ParsedCatalog[],
  };

  result.data.catalogs.forEach((catalog) => {
    let key: keyof typeof catalogs = 'categories';
    if (catalog.level === 1) key = 'categories';
    else if (catalog.level === 2) key = 'products';
    else if (catalog.level === 3) key = 'varieties';
    else return;

    const parsedCatalog: ParsedCatalog = {
      id: catalog.id,
      status: catalog.status,
      level: catalog.level,
      code: '',
      type: AimeosEntityType.Catalog,
      label: catalog.label,
      parentId: catalog.parentid,
      attributes: catalog.lists.attribute.map((attr) => attrIDMapping.get(attr.refid)!).filter(Boolean),
    };

    if (parsedCatalog.level === 2) {
      parsedCatalog.units = OrderUnitsType.Weight;
      if (parsedCatalog.attributes?.find((a) => a.code === UnitAttributeCode.Pieces)) {
        parsedCatalog.units = OrderUnitsType.Pieces;
      }
    }

    catalogs[key].push(parsedCatalog);
  });

  const catalogIDMapping = new Map<string, ParsedCatalog>();
  catalogs.categories.forEach((c) => catalogIDMapping.set(c.id, c));
  catalogs.products.forEach((c) => catalogIDMapping.set(c.id, c));
  catalogs.varieties.forEach((c) => catalogIDMapping.set(c.id, c));

  return {
    ...result,
    catalogs,
    catalogIDMapping,
    catalogItems: {
      categories: catalogs.categories.map<DataItem<ParsedCatalog>>((c) => ({ id: c.id, label: c.label, value: c })),
      products: catalogs.products.map<DataItem<ParsedCatalog>>((c) => ({ id: c.id, label: c.label, value: c })),
      varieties: catalogs.varieties.map<DataItem<ParsedCatalog>>((c) => ({ id: c.id, label: c.label, value: c })),
    },
    attributes,
    attributeItems: {
      orderTypes: attributes.orderTypes.map<DataItem<ParsedAttribute>>((a) => ({ id: a.id, label: a.label, value: a })),
      orderStatus: attributes.orderStatus.map<DataItem<ParsedAttribute>>((a) => ({
        id: a.id,
        label: a.label,
        value: a,
      })),
      tradeStatus: attributes.tradeStatus.map<DataItem<ParsedAttribute>>((a) => ({
        id: a.id,
        label: a.label,
        value: a,
      })),
      units: attributes.units.map<DataItem<ParsedAttribute>>((a) => ({
        id: a.id,
        label: a.label,
        value: a,
      })),
      delivery: attributes.deliveryTypes.map<DataItem<ParsedAttribute>>((a) => ({
        id: a.id,
        label: a.label,
        value: a,
      })),
      provinces: attributes.provinces.map<DataItem<ParsedAttribute>>((a) => ({
        id: a.id,
        label: a.label,
        value: a,
      })),
    },
    attrMapping,
    attrIDMapping,
    attrTypes,
  };
};

export type ParsedAppValuesObject = ReturnType<typeof selector>;

export function useGetAllValuesQuery(enabled = true) {
  return useQuery({
    queryKey: getAllValuesQueryKey(),
    queryFn: getAllAppValues,
    enabled,
    select: selector,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    staleTime: 1000 * 60 * 60, // 1 hour
  });
}
