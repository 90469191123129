export default function ChatIcon({ size = 16, color: _, filled: _1, ...props }: IconProps) {
  return (
    <svg width={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="13" r="13" fill="#E9E9F4" />
      <path
        d="M16.7083 8H10.2917C9.00833 8 8 9.00833 8 10.2917V17.9C8 18.4958 8.50417 19 9.1 19C9.375 19 9.69583 18.8625 9.87917 18.6792L10.9792 17.5792C11.2542 17.3042 11.575 17.1667 11.9417 17.1667H16.7083C17.9917 17.1667 19 16.1583 19 14.875V10.2917C19 9.00833 17.9917 8 16.7083 8Z"
        fill="#272733"
      />
    </svg>
  );
}
