export default function RatingStarIcon({ size = 36, halfStar, ...props }: IconProps & { halfStar?: boolean }) {
  if (halfStar) {
    return (
      <svg width={size} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M58.1698 3.54524C60.7426 -1.18174 67.2574 -1.18175 69.8302 3.54523L86.0509 33.347C87.0056 35.1011 88.6364 36.3402 90.5265 36.7477L122.64 43.6722C127.734 44.7705 129.747 51.2504 126.244 55.2701L104.155 80.6131C102.855 82.1047 102.232 84.1096 102.445 86.1155L106.072 120.197C106.647 125.603 101.377 129.607 96.6384 127.365L66.7661 113.226C65.0079 112.394 62.9921 112.394 61.2339 113.226L31.3616 127.365C26.6234 129.607 21.3528 125.603 21.928 120.197L25.5548 86.1155C25.7682 84.1096 25.1453 82.1047 23.8453 80.6131L1.75639 55.2701C-1.74721 51.2504 0.265977 44.7705 5.35967 43.6722L37.4735 36.7477C39.3636 36.3402 40.9944 35.1011 41.9491 33.347L58.1698 3.54524Z"
          fill="#FFD4D4"
        />
        <path
          d="M58.1698 3.54524C60.7426 -1.18174 67.2574 -1.18175 69.8302 3.54523C72.4031 8.2722 86.0509 33.347 86.0509 33.347C87.0056 35.1011 88.6364 36.3402 90.5265 36.7477L122.64 43.6722C127.734 44.7705 129.747 51.2504 126.244 55.2701L104.155 80.6131C102.855 82.1047 102.232 84.1096 102.445 86.1155L106.072 120.197C106.647 125.603 101.377 129.607 96.6384 127.365L66.7661 113.226C65.0079 112.394 62.9921 112.394 61.2339 113.226L31.3616 127.365C26.6234 129.607 21.3528 125.603 21.928 120.197L25.5548 86.1155C25.7682 84.1096 25.1453 82.1047 23.8453 80.6131L1.75639 55.2701C-1.74721 51.2504 0.265977 44.7705 5.35967 43.6722L37.4735 36.7477C39.3636 36.3402 40.9944 35.1011 41.9491 33.347L58.1698 3.54524Z"
          fill="url(#paint0_linear_240_9)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_240_9"
            x1="-4.84288e-07"
            y1="62"
            x2="128"
            y2="62"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" />
            <stop offset="0.5049" stopColor="currentColor" />
            <stop offset="0.505" stopColor="currentColor" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg width={size} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M58.1698 3.54524C60.7426 -1.18174 67.2574 -1.18175 69.8302 3.54523L86.0509 33.347C87.0056 35.1011 88.6364 36.3402 90.5265 36.7477L122.64 43.6722C127.734 44.7705 129.747 51.2504 126.244 55.2701L104.155 80.6131C102.855 82.1047 102.232 84.1096 102.445 86.1155L106.072 120.197C106.647 125.603 101.377 129.607 96.6384 127.365L66.7661 113.226C65.0079 112.394 62.9921 112.394 61.2339 113.226L31.3616 127.365C26.6234 129.607 21.3528 125.603 21.928 120.197L25.5548 86.1155C25.7682 84.1096 25.1453 82.1047 23.8453 80.6131L1.75639 55.2701C-1.74721 51.2504 0.265977 44.7705 5.35967 43.6722L37.4735 36.7477C39.3636 36.3402 40.9944 35.1011 41.9491 33.347L58.1698 3.54524Z"
        fill="currentColor"
      />
    </svg>
  );
}
