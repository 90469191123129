import React, { useEffect, useState } from 'react';

export default function useContainerHeight(elementId: string, defaultValue?: number, dependencies?: any[]) {
  const [containerHeight, setContainerHeight] = React.useState(defaultValue);
  const [trigger, setTrigger] = useState(false);

  const hasDependencies = !!dependencies && dependencies.length > 0;
  useEffect(() => {
    if (!hasDependencies) {
      const interval = setInterval(() => setTrigger((prev) => !prev), 200);
      return () => clearInterval(interval);
    }
  }, [hasDependencies]);

  React.useLayoutEffect(
    () => {
      const element = document.getElementById(elementId);
      const newValue = element?.clientHeight || 0;
      setContainerHeight((prev) => (prev !== newValue ? newValue : prev));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    hasDependencies ? dependencies : [trigger],
  );

  return containerHeight;
}
