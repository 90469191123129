import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getCsrfQueryKey, useGetCsrfQuery } from 'api/queries/common/csrf';
import { CsrfStorage } from 'api/config/csrf';
import { GetCSRFResponse } from 'api/queries/common/csrf.types';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'utils/constants';
import { useLogoutMutation } from 'api/mutations/auth/logout';
import { appLogout } from 'utils/session';
import { initialDataSettings, useGetAppSettingsQuery } from 'api/queries/common/config';
import { AppStateContextType, AppStateContextValuesType } from './types';

const defaultContextValue: AppStateContextType = {
  locale: DEFAULT_LOCALE,
  currency: DEFAULT_CURRENCY,
  isAuthorised: false,
  setIsAuthorised() {},
  logout: (_redirectTo?: string) => {},
  csrfReady: false,
  blockRequests: false,
  setBlockRequests() {},
  settings: initialDataSettings,
};

const AppStateContext = createContext<AppStateContextType>(defaultContextValue);

export default function AppStateProvider({ children }: PropsWithChildren) {
  const [values] = useState<AppStateContextValuesType>(defaultContextValue);
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);
  const [blockRequests, setBlockRequests] = useState(false);

  const { data: csrfResult } = useGetCsrfQuery();
  const { mutateAsync: logoutUser } = useLogoutMutation();

  const queryClient = useQueryClient();

  useEffect(() => {
    CsrfStorage.onChange = (v) => {
      queryClient.setQueryData(getCsrfQueryKey(), (oldData?: GetCSRFResponse) =>
        oldData ? ({ ...oldData, meta: { ...oldData?.meta, csrf: v } } as GetCSRFResponse) : oldData,
      );
    };
  }, [queryClient, values.locale, values.currency]);

  useLayoutEffect(() => {
    if (localStorage.getItem('access_token')) {
      setIsAuthorised(true);
    }
  }, []);

  const logout = useCallback(
    (redirectTo?: string) => {
      logoutUser()
        .catch(console.error)
        .finally(() => appLogout(redirectTo));
    },
    [logoutUser],
  );

  const { data: settings } = useGetAppSettingsQuery();

  return (
    <AppStateContext.Provider
      value={{
        ...values,
        isAuthorised,
        setIsAuthorised,
        blockRequests,
        setBlockRequests,
        logout,
        csrfReady: !!csrfResult?.success,
        settings: settings || initialDataSettings,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppState = () => useContext(AppStateContext);
