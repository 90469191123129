export default function MyOrdersIcon({ size = 28, isActive }: { size?: number; isActive?: boolean }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" className={isActive ? 'fill-brand-red-main' : 'fill-brand-gray-soft'} />
      <rect x="8" y="6" width="12" height="4" rx="1" className={isActive ? 'fill-white' : 'fill-brand-purple-gray'} />
      <rect x="8" y="12" width="12" height="4" rx="1" className={isActive ? 'fill-white' : 'fill-brand-purple-gray'} />
      <rect x="8" y="18" width="12" height="4" rx="1" className={isActive ? 'fill-white' : 'fill-brand-purple-gray'} />
    </svg>
  );
}
