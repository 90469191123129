import { useNavigate } from 'react-router';
import ReviewStars from 'components/ReviewStars';
import { useGetUserDetailsQuery } from 'api/queries/customer/get-one';
import { VerticalDashedDelimiter } from 'components/DashedDelimiter';
import ProfileIcon from 'assets/svg/Profile';

type Props = {
  userId: string;
  navigateToProfile?: boolean;
};

export default function CustomerInfo({ userId, navigateToProfile }: Props) {
  const { data: userInfo } = useGetUserDetailsQuery(userId);
  const rating = userInfo?.rating || 0;
  const ratingLabel = rating ? rating.toFixed(1) : '—';
  const reviewsCount = userInfo?.reviewsCount || 0;

  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row my-3">
      <button
        disabled={!navigateToProfile}
        type={'submit'}
        onClick={() => {
          navigate(`/user/${userId}`);
        }}
        className="flex flex-row justify-between items-center md:justify-start gap-4"
      >
        <div className="flex flex-row justify-center items-center gap-2">
          <ProfileIcon size={24} isActive />

          <p className="text-sm font-normal whitespace-nowrap">
            <span className="font-bold">User </span>
            <span>{userInfo?.username || '—'} </span>
          </p>

          <VerticalDashedDelimiter
            height={20}
            className="hidden md:flex md:ml-2 pt-1"
            strokeDasharray={'0.2 6'}
            strokeLinecap="round"
          />
        </div>

        <div className="flex flex-row gap-4">
          <ReviewStars rating={rating} />
          <p className="text-sm font-bold">{ratingLabel}</p>
        </div>

        <VerticalDashedDelimiter
          height={20}
          className="hidden md:flex pt-1"
          strokeDasharray={'0.2 6'}
          strokeLinecap="round"
        />

        <p className="md:flex hidden text-sm font-bold whitespace-nowrap">
          <span className="font-normal">Reviews</span>
          <span className="pl-2">{reviewsCount}</span>
        </p>
      </button>

      <p className="md:hidden flex text-sm font-bold pt-1 pl-8 whitespace-nowrap">
        <span className="font-normal">Reviews</span>
        <span className="pl-2">{reviewsCount}</span>
      </p>
    </div>
  );
}
