export const MailIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24697 5.53298C3.85645 5.9235 3.85645 6.55204 3.85645 7.80912V10.4758C3.85645 11.7329 3.85645 12.3614 4.24697 12.7519C4.63749 13.1425 5.26603 13.1425 6.52311 13.1425H11.4755C12.7326 13.1425 13.3611 13.1425 13.7516 12.7519C14.1422 12.3614 14.1422 11.7329 14.1422 10.4758V7.80912C14.1422 6.55204 14.1422 5.9235 13.7516 5.53298C13.3611 5.14246 12.7326 5.14246 11.4755 5.14246H6.52311C5.26603 5.14246 4.63749 5.14246 4.24697 5.53298ZM5.81685 7.05844C5.61261 6.92228 5.33667 6.97747 5.20051 7.18171C5.06436 7.38594 5.11954 7.66188 5.32378 7.79804L8.50582 9.9194C8.80439 10.1185 9.19337 10.1185 9.49195 9.9194L12.674 7.79804C12.8782 7.66188 12.9334 7.38594 12.7973 7.18171C12.6611 6.97747 12.3852 6.92228 12.1809 7.05844L8.99888 9.1798L5.81685 7.05844Z"
        fill="#272733"
      />
    </svg>
  );
};
