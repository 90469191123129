import { FC, PropsWithChildren } from 'react';

export default function SectionSeparator({ children, Icon }: PropsWithChildren<{ Icon: FC<IconProps> }>) {
  return (
    <div className="flex items-center gap-3">
      <Icon size={26} className={'min-w-6'} />

      <h3 className="font-bold text-xl text-brand-black">{children}</h3>

      <div className="flex-grow flex-col h-0.5 pl-10 hidden md:flex">
        <svg height={2} width="100%">
          <line x1={0} y1={0} x2="100%" y2={0} strokeWidth={4} stroke={'#D2D2E8'} strokeDasharray={'2 8'} />
        </svg>
      </div>
    </div>
  );
}
