import { SVGProps } from 'react';

type Props = SVGProps<any> & { strokeDasharray?: `${number} ${number}`; strokeLinecap?: 'round' | 'square' };

export function VerticalDashedDelimiter({ strokeDasharray = '2 4', strokeLinecap = 'square', ...props }: Props) {
  return (
    <svg height={'100%'} width={2} {...props}>
      <line
        x1={1}
        y1={1}
        x2={1}
        y2={'100%'}
        strokeWidth={2}
        stroke={'#D2D2E8'}
        strokeLinecap={strokeLinecap}
        strokeDasharray={strokeDasharray}
      />
    </svg>
  );
}

export function HorizontalDashedDelimiter({ strokeDasharray = '2 4', strokeLinecap = 'square', ...props }: Props) {
  return (
    <svg height={2} width="100%" {...props}>
      <line
        x1={1}
        y1={1}
        x2="100%"
        y2={1}
        strokeWidth={2}
        stroke={'#D2D2E8'}
        strokeLinecap={strokeLinecap}
        strokeDasharray={strokeDasharray}
      />
    </svg>
  );
}
