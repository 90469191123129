import {
  AimeosAggregatedReview,
  AimeosAttribute,
  AimeosAttributeType,
  AimeosCatalog,
  AimeosCustomer,
  AimeosEntityType,
  AimeosManyResponse,
  AimeosProduct,
  AimeosProductList,
  AimeosProductProperty,
  AimeosReview,
  AimeosSingleResponse,
  IncludedEntities,
} from './types';
import { OrderUnitsType } from '../utils/enums';

export function parseProduct(p: AimeosProduct) {
  return {
    id: p.id,
    entityType: AimeosEntityType.Product,
    systemStatus: p.attributes['product.status'],
    label: p.attributes['product.label'],
    code: p.attributes['product.code'],
    createdAt: p.attributes['product.ctime'],
    unitsPrice: +(p.attributes['units_price'] || '0'),
    unitsAmount: +(p.attributes['units_amount'] || 0),
    deliveryDate: p.attributes.delivery_date || null,
    deliveryLocation: p.attributes.delivery_location || null,
    productType: p.attributes['product.type'],
    canBeReviewed: !!p.attributes.can_be_reviewed,
  };
}

export type ParsedProduct = ReturnType<typeof parseProduct>;

export function parseProductProperty(pr: AimeosProductProperty) {
  return {
    id: pr.id,
    entityType: AimeosEntityType.ProductProperty,
    value: pr.attributes['product.property.value'],
    type: pr.attributes['product.property.type'],
  };
}

export type ParsedProductProperty = ReturnType<typeof parseProductProperty>;

export function parseProductLists(pr: AimeosProductList) {
  return {
    id: pr.id,
    entityType: AimeosEntityType.ProductList,
    refId: pr.attributes['product.lists.refid'],
    parentId: pr.attributes['product.lists.parentid'],
    domain: pr.attributes['product.lists.domain'],
  };
}

export type ParsedProductList = ReturnType<typeof parseProductLists>;

export function parseAttribute(a: AimeosAttribute) {
  return {
    id: a.id,
    entityType: AimeosEntityType.Attribute,
    label: a.attributes['attribute.label'],
    type: a.attributes['attribute.type'],
    code: a.attributes['attribute.code'],
  };
}

export type ParsedAttribute = ReturnType<typeof parseAttribute>;

export function parseAttributeType(at: AimeosAttributeType) {
  return {
    id: at.id,
    entityType: AimeosEntityType.AttributeType,
    label: at.attributes['attribute.type.label'],
    code: at.attributes['attribute.type.code'],
    name: at.attributes['attribute.type.name'],
  };
}

export type ParsedAttributeType = ReturnType<typeof parseAttributeType>;

export function parseCatalog(c: AimeosCatalog) {
  return {
    id: c.id,
    status: c.attributes['catalog.status'],
    type: AimeosEntityType.Catalog,
    label: c.attributes['catalog.label'],
    code: c.attributes['catalog.code'],
    level: c.attributes['catalog.level'],
    parentId: c.attributes['catalog.parentid'],
  };
}

export type ParsedCatalog = ReturnType<typeof parseCatalog> & {
  units?: OrderUnitsType;
  attributes?: Array<ParsedAttribute>;
};

export function parseCustomer(c: AimeosCustomer) {
  return {
    id: c.id,
    type: AimeosEntityType.Customer,
    label: c.attributes.username,
    code: c.attributes.username,
  };
}

export type ParsedCustomer = ReturnType<typeof parseCustomer>;

export function parseReview(c: AimeosReview) {
  return {
    id: c.id,
    type: AimeosEntityType.Review,
    comment: c.attributes['review.comment'],
    createdAt: c.attributes['review.ctime'],
    customerId: c.attributes['review.customerid'],
    domain: c.attributes['review.domain'],
    editor: c.attributes['review.editor'],
    name: c.attributes['review.name'],
    orderProductId: c.attributes['review.orderproductid'],
    rating: c.attributes['review.rating'],
    refId: c.attributes['review.refid'],
    response: c.attributes['review.response'],
    siteId: c.attributes['review.siteid'],
    status: c.attributes['review.status'],
    productCode: c.attributes['product_code'],
    productCustomerId: c.attributes['product_customerid'],
    productCustomerUsername: c.attributes['product_customerid_username'],
    productType: c.attributes['product_type'],
  };
}

export type ParsedReview = ReturnType<typeof parseReview>;

export function parseAggregatedReview(c: AimeosReview) {
  return {
    id: c.id,
    type: c.type,
    attributes: c.attributes,
  };
}

export type ParsedAggregatedReview = ReturnType<typeof parseAggregatedReview>;

export function parseIncludedEntities(i?: IncludedEntities) {
  const included = (Array.isArray(i) ? i : [i]).filter(Boolean) as IncludedEntities;

  const filterEntities = <T>(type: string) => included.filter((val) => val.type === type) as T[];

  const customers = filterEntities<AimeosCustomer>(AimeosEntityType.Customer).map(parseCustomer);
  const catalogs = filterEntities<AimeosCatalog>(AimeosEntityType.Catalog).map(parseCatalog);
  const products = filterEntities<AimeosProduct>(AimeosEntityType.Product).map(parseProduct);
  const productProperties = filterEntities<AimeosProductProperty>(AimeosEntityType.ProductProperty).map(
    parseProductProperty,
  );
  const productLists = filterEntities<AimeosProductList>(AimeosEntityType.ProductList).map(parseProductLists);
  const attributes = filterEntities<AimeosAttribute>(AimeosEntityType.Attribute).map(parseAttribute);
  const attributeTypes = filterEntities<AimeosAttributeType>(AimeosEntityType.AttributeType).map(parseAttributeType);

  return {
    customers,
    catalogs,
    products,
    productProperties,
    productLists,
    attributes,
    attributeTypes,
  };
}

export type ParsedIncludedEntities = ReturnType<typeof parseIncludedEntities>;

type AimeosEntitiesMapping = {
  [AimeosEntityType.Attribute]: AimeosAttribute;
  [AimeosEntityType.AttributeType]: AimeosAttributeType;
  [AimeosEntityType.Catalog]: AimeosCatalog;
  [AimeosEntityType.Customer]: AimeosCustomer;
  [AimeosEntityType.Product]: AimeosProduct;
  [AimeosEntityType.ProductProperty]: AimeosProductProperty;
  [AimeosEntityType.ProductList]: AimeosProductList;
  [AimeosEntityType.Review]: AimeosReview;
  [AimeosEntityType.AggregatedReview]: AimeosAggregatedReview;
};

type ParsedEntitiesMapping = {
  [AimeosEntityType.Attribute]: ParsedAttribute;
  [AimeosEntityType.AttributeType]: ParsedAttributeType;
  [AimeosEntityType.Customer]: ParsedCustomer;
  [AimeosEntityType.Catalog]: ParsedCatalog;
  [AimeosEntityType.Product]: ParsedProduct;
  [AimeosEntityType.ProductProperty]: ParsedProductProperty;
  [AimeosEntityType.ProductList]: ParsedProductList;
  [AimeosEntityType.Review]: ParsedReview;
  [AimeosEntityType.AggregatedReview]: ParsedAggregatedReview;
};

const parsersByEntityType = {
  [AimeosEntityType.Attribute]: parseAttribute,
  [AimeosEntityType.AttributeType]: parseAttributeType,
  [AimeosEntityType.Customer]: parseCustomer,
  [AimeosEntityType.Catalog]: parseCatalog,
  [AimeosEntityType.Product]: parseProduct,
  [AimeosEntityType.ProductProperty]: parseProductProperty,
  [AimeosEntityType.ProductList]: parseProductLists,
  [AimeosEntityType.Review]: parseReview,
  [AimeosEntityType.AggregatedReview]: parseAggregatedReview,
};

export function parseSingleResponse<E extends AimeosEntityType>(r: AimeosSingleResponse<AimeosEntitiesMapping[E]>) {
  const originData = Array.isArray(r.data) ? r.data[0] : r.data;
  const entityType = originData.type as AimeosEntityType;

  const data: ParsedEntitiesMapping[E] = parsersByEntityType[entityType](originData as any) as any;

  const included = parseIncludedEntities(r.included);

  return {
    data,
    ...included,
  } as { data: ParsedEntitiesMapping[E] } & ParsedIncludedEntities;
}

export function parseManyResponse<E extends AimeosEntityType>(r: AimeosManyResponse<AimeosEntitiesMapping[E]>) {
  let list = r.data;

  if (!Array.isArray(list)) {
    list = [list];
  }

  const included = parseIncludedEntities(r.included);

  const items = list.map((l) => {
    const entityType = l.type as AimeosEntityType;
    const data: ParsedEntitiesMapping[E] = parsersByEntityType[entityType](l as any) as any;

    const itemIncluded: ParsedIncludedEntities = {
      attributes: [],
      attributeTypes: [],
      customers: [],
      catalogs: [],
      products: [],
      productProperties: [],
      productLists: [],
    };

    if (l.relationships?.attribute?.data && Array.isArray(l.relationships?.attribute?.data)) {
      l.relationships.attribute.data.forEach((rel) => {
        const relatedItem = included.attributes.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.attributes.push(relatedItem);
        }
      });
    }

    const currentAttributes = l?.attribute;
    if (!itemIncluded.attributes?.length && currentAttributes && Array.isArray(currentAttributes)) {
      currentAttributes.forEach((rel) => {
        const relatedItem = included.attributes.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.attributes.push(relatedItem);
        }
      });
    }

    if (l.relationships?.['attribute/type']?.data && Array.isArray(l.relationships?.['attribute/type']?.data)) {
      l.relationships['attribute/type'].data.forEach((rel) => {
        const relatedItem = included.attributeTypes.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.attributeTypes.push(relatedItem);
        }
      });
    }

    if (l.relationships?.catalog?.data && Array.isArray(l.relationships?.catalog?.data)) {
      l.relationships.catalog.data.forEach((rel) => {
        const relatedItem = included.catalogs.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.catalogs.push(relatedItem);
        }
      });
    }

    const currentCatalogs = l?.catalog;
    if (!itemIncluded.catalogs?.length && currentCatalogs && Array.isArray(currentCatalogs)) {
      currentCatalogs.forEach((rel) => {
        const relatedItem = included.catalogs.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.catalogs.push(relatedItem);
        }
      });
    }

    if (l.relationships?.product?.data && Array.isArray(l.relationships?.product?.data)) {
      l.relationships.product.data.forEach((rel) => {
        const relatedItem = included.products.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.products.push(relatedItem);
        }
      });
    }

    if (l.relationships?.['product/property']?.data && Array.isArray(l.relationships?.['product/property']?.data)) {
      l.relationships['product/property'].data.forEach((rel) => {
        const relatedItem = included.productProperties.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.productProperties.push(relatedItem);
        }
      });
    }

    if (l.relationships?.['product/lists']?.data && Array.isArray(l.relationships?.['product/lists']?.data)) {
      l.relationships['product/lists'].data.forEach((rel) => {
        const relatedItem = included.productLists.find((i) => i.id === rel.id);
        if (relatedItem) {
          itemIncluded.productLists.push(relatedItem);
        }
      });
    }

    return {
      data,
      ...itemIncluded,
    };
  });

  return items as ({ data: ParsedEntitiesMapping[E] } & ParsedIncludedEntities)[];
}
