import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { KEYS, logEvent } from 'utils/analytics';
import { useDataValues } from 'providers/DataValues';
import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';
import { useModals } from 'providers/Modals';

type Props = {
  onPressItem: () => void;
};

const HamburgerMenuMobileLayout = ({ onPressItem }: Props) => {
  const location = useLocation();
  const { profile } = useDataValues();
  const { open: openModal } = useModals();

  return (
    <div className="w-full flex flex-1 flex-col justify-between px-6 text-brand-black">
      <div className="space-y-4 flex flex-col">
        <span className="flex items-center text-xl font-black px-1 h-[60px]">Menu</span>
        <Link
          to="/"
          className={clsx(
            'text-base px-3 py-1 rounded-full whitespace-nowrap w-[110px]',
            location.pathname === '/' ? 'bg-black text-white' : 'bg-transparent text-black',
          )}
          onClick={onPressItem}
        >
          <span>{'Dashboard'}</span>
        </Link>
        <Link
          to="/marketplace"
          className={clsx(
            'text-base px-3 py-1 rounded-full whitespace-nowrap w-[110px]',
            location.pathname === '/marketplace' ? 'bg-black text-white' : 'bg-transparent text-black',
          )}
          onClick={onPressItem}
        >
          {'Marketplace'}
        </Link>
        <Link
          to="/orders"
          className={clsx(
            'text-base px-3 py-1 rounded-full whitespace-nowrap w-[110px] ',
            location.pathname === '/orders' ? 'bg-black text-white' : 'bg-transparent text-black',
          )}
          onClick={onPressItem}
        >
          {'My Orders'}
        </Link>
        <Link
          to="/trades"
          className={clsx(
            'text-base px-3 py-1 rounded-full whitespace-nowrap w-[110px]',
            location.pathname === '/trades' ? 'bg-black text-white' : 'bg-transparent text-black',
          )}
          onClick={onPressItem}
        >
          {'My Trades'}
        </Link>
        <Link
          className={clsx(
            'text-base px-3 py-1 rounded-full whitespace-nowrap w-[110px]',
            location.pathname === '/profile' ? 'bg-black text-white' : 'bg-transparent text-black',
          )}
          onClick={onPressItem}
          to="/profile"
        >
          My Profile
        </Link>
        <Link className="text-base px-3" onClick={onPressItem} to="/faq">
          FAQ
        </Link>
        <Link
          className="text-base px-3"
          onClick={() => {
            onPressItem();
            logEvent(KEYS.open_tutorials);
          }}
          to="/tutorials"
        >
          Tutorials
        </Link>
        <button
          className="text-base px-3 text-left"
          onClick={() => {
            openModal('report-issue', { email: profile.email || '' });
            onPressItem?.();
          }}
        >
          Report an issue
        </button>

        <div />
        <div />

        <HorizontalDashedDelimiter className="flex flex-1" strokeDasharray={'0.1 8'} strokeLinecap="round" />
        <p className={'text-sm text-brand-gray-dark break-words'}>
          Email: {profile.email}
          <br />
          Username: {profile.username}
        </p>
      </div>

      <div className="flex flex-row justify-between mb-10 mt-4 text-xs">
        <Link
          to={'/privacy'}
          onClick={() => {
            logEvent(KEYS.privacy_policy);
          }}
        >
          Privacy Policy
        </Link>

        <Link
          to={'/terms'}
          onClick={() => {
            logEvent(KEYS.terms_conditions);
          }}
        >
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default HamburgerMenuMobileLayout;
