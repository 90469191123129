import { AimeosProductType } from '../api/types';
import { ParsedOrder } from '../api/order.parsers';
import { useIsReviewExistedQuery } from '../api/queries/review/is-existed';

export default function useOrderCanLeaveReview(
  mainProductType: AimeosProductType,
  order?: Nullable<ParsedOrder>,
  trade?: Nullable<ParsedOrder>,
) {
  const product = mainProductType === AimeosProductType.Article ? trade : order;

  const readyToReview = Boolean(product?.canBeReviewed);

  const { data: hasExistedReview } = useIsReviewExistedQuery(
    {
      productId: product?.id || '',
      productCustomerId: (mainProductType === AimeosProductType.Article ? product?.tradeOwner : product?.owner) || '',
    },
    !!product && product.canBeReviewed,
  );

  if (typeof hasExistedReview === 'boolean') {
    return readyToReview && !hasExistedReview;
  }

  return false;
}
