import clsx from 'clsx';

export const fieldsGroupGridClsx = clsx(
  'pt-5 pb-6 px-8 bg-brand-purple-card rounded-xl',
  'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-5',
);

export const previewGroupGridClsx = clsx(
  'py-3 md:py-6 px-8 bg-brand-purple-card rounded-xl',
  'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-3 md:gap-x-8 gap-y-5',
  '[&>*>.divider]:visible',
  //
  '[&>*:nth-child(2n)>.divider]:invisible [&>*>.divider]:visible',
  'md:[&>*:nth-child(2n)>.divider]:visible md:[&>*:nth-child(3n)>.divider]:invisible',
  'lg:[&>*:nth-child(3n)>.divider]:visible lg:[&>*:nth-child(4n)>.divider]:invisible',
);
