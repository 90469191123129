import { PropsWithChildren } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const memoryQueryClient = new QueryClient();
export const cachedQueryClient = new QueryClient({
  // user for persisted caching. Details https://tanstack.com/query/latest/docs/react/plugins/createSyncStoragePersister#usage
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

const persistOptions = {
  persister: localStoragePersister,
};

export default function TanstackQueryProvider({ children }: PropsWithChildren) {
  return (
    <PersistQueryClientProvider client={cachedQueryClient} persistOptions={persistOptions}>
      {children}
    </PersistQueryClientProvider>
  );
}
