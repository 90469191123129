import { PropsWithChildren } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import { VerticalDashedDelimiter } from '../DashedDelimiter';

type Props = PropsWithChildren<{ label: string; loading?: boolean }>;

export default function FieldBlock({ children, label, loading }: Props) {
  return (
    <div className={'flex flex-col flex-1 gap-1.5 relative pr-1.5'}>
      <p className={'text-xs text-brand-purple-gray'}>
        {label} {loading && <LoadingIndicator size={12} className={'inline ml-0.5'} />}
      </p>
      <p className={'text-sm md:text-base text-brand-black font-bold break-words'}>
        {loading ? 'Loading...' : children}
      </p>

      <div className={'divider absolute right-0 top-0 bottom-0 w-1'}>
        <VerticalDashedDelimiter strokeDasharray={'0.5 5'} strokeLinecap={'round'} />
      </div>
    </div>
  );
}
