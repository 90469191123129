import { initializeApp } from 'firebase/app';
import {
  AnalyticsCallOptions,
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId as setFirebaseUserId,
} from 'firebase/analytics';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
const firebaseAnalytics = getAnalytics(app);

enum KEYS {
  // Source
  install_from_appstore = 'install_from_appstore',
  install_from_google_play = 'install_from_google_play',
  install_from_facebook_ads = 'install_from_facebook_ads',
  install_from_google_ads = 'install_from_google_ads',
  install_from_website = 'install_from_website',
  // Sign Up
  click_create_account = 'click_create_account',
  terms_conditions = 'terms_conditions',
  privacy_policy = 'privacy_policy',
  step_1_email_verification = 'step_1_email_verification',
  step_2_phone_verification = 'step_2_phone_verification',
  step_3_password = 'step_3_password',
  step_4_go_to_log_in = 'step_4_go_to_log_in',

  // Log In
  click_login_button = 'click_login_button',
  reset_password = 'reset_password',

  // Users
  number_of_active_users = 'number_of_active_users',
  account_deleted = 'account_deleted',
  users_with_orders = 'users_with_orders',
  users_with_feedback = 'users_with_feedback',

  // Place Order
  click_place_order = 'click_place_order',
  click_buy = 'click_buy',
  click_sell = 'click_sell',
  click_view_sell_order = 'click_view_sell_order',
  click_view_buy_order = 'click_view_buy_order',
  click_confirm_save_buy_order = 'click_confirm_save_buy_order',
  click_confirm_save_sell_order = 'click_confirm_save_sell_order',

  // Accept/Deny Trade Offer
  click_profile_reviews = 'click_profile_reviews',
  click_decline_trade = 'click_decline_trade',
  confirm_declining_trade = 'confirm_declining_trade',
  click_accept_trade = 'click_accept_trade',
  received_trade_offer = 'users_received_trade_offer',

  // Tutorials
  open_tutorials = 'open_tutorials',

  // Support
  contact_smoove_support = 'contact_smoove_support',
  submit_issue_report = 'submit_issue_report',
  submit_problem_report = 'submit_problem_report',

  // Engagement
  avg_time_spent_on_application = 'avg_time_spent_on_application',
}

const logEvent = (
  eventName: KEYS,
  eventParams?: { [key: string]: any } | undefined,
  options?: AnalyticsCallOptions | undefined,
) => {
  logFirebaseEvent(firebaseAnalytics, eventName, eventParams, options);
};

const setUserId = (id: string | null) => {
  setFirebaseUserId(firebaseAnalytics, id);
};

export { logEvent, setUserId, KEYS };
