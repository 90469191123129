import { Virtuoso } from 'react-virtuoso';
import { useCallback, useId } from 'react';
import useContainerHeight from 'hooks/useContainerHeight';
import LoadingIndicator from 'components/LoadingIndicator';
import { ParsedReviews, useGetReviewsInfinityQuery } from 'api/queries/review/review';
import ReviewItem from './ReviewItem';

type Props = {
  userId: string;
};

export default function ReviewsTable({ userId }: Props) {
  const containerId = useId();
  const containerHeight = useContainerHeight(containerId, 10);

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useGetReviewsInfinityQuery(
    {
      product_customer: userId,
    },
    !!userId,
  );

  const Footer = useCallback(
    () => (
      <>
        {isFetchingNextPage ? (
          <div className="w-full flex items-center justify-center py-4">
            <LoadingIndicator size={32} className={'text-brand-red-main'} />
          </div>
        ) : null}
      </>
    ),
    [isFetchingNextPage],
  );

  const itemContent = useCallback((_: number, { data }: ParsedReviews[0]) => {
    return (
      <div key={data.id} className="w-full py-1 px-2">
        <ReviewItem review={data} />
      </div>
    );
  }, []);

  const EmptyPlaceholder = useCallback(
    () => (
      <div className="flex h-[92px] items-center justify-center text-sm text-black-brand w-full">
        {isLoading ? (
          <LoadingIndicator size={32} className="text-brand-red-origin self-center" />
        ) : (
          "You don't have any reviews"
        )}
      </div>
    ),
    [isLoading],
  );

  const needToFetchMore = !isFetchingNextPage && data && data.items.length < (data.pages?.[0]?.meta?.total || 0);

  return (
    <div id={containerId} className="flex flex-1 flex-grow px-4 lg:px-8 w-full md:w-[60%]">
      {!!containerHeight && (
        <Virtuoso
          style={{ height: containerHeight, width: '100%' }}
          data={data?.items || []}
          itemContent={itemContent}
          components={{
            EmptyPlaceholder,
            Footer,
          }}
          endReached={
            needToFetchMore
              ? () => {
                  fetchNextPage();
                }
              : undefined
          }
        />
      )}
    </div>
  );
}
