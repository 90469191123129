import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import HomePage from 'pages/client/Home';
import OrdersPage from 'pages/client/Orders';
import OrdersMakePage from 'pages/client/Orders/Make';
import OrderDetailsPage from 'pages/client/Orders/Details';
import MarketplaceOrderDetailsPage from 'pages/client/Marketplace/Details';
import TradesPage from 'pages/client/Trades';

import LoginPage from 'pages/guest/Login';
import SignUpPage from 'pages/guest/SignUp';
import ForgotPasswordPage from 'pages/guest/ForgotPassword';

import PrivacyPolicyPage from 'pages/common/Privacy';
import TermsAndConditionsPage from 'pages/common/Terms';
import Page404 from 'pages/common/404';

import { useAppState } from 'providers/AppState';

import MarketplacePage from 'pages/client/Marketplace';
import OrdersEditPage from 'pages/client/Orders/Edit';
import UserPage from 'pages/client/User';
import MyProfilePage from 'pages/client/MyProfile/component';
import TradeDetailsPage from 'pages/client/Trades/Details/component';
import FAQPage from 'pages/client/FAQ/component';
import TutorialsPage from 'pages/client/Tutorials/component';

import BackgroundWithFormWrapper from './wrappers/BackgroundWithForm';
import BackgroundWithDocumentWrapper from './wrappers/BackgroundWithDocument';
import AuthorizedBackgroundWrapper from './wrappers/AuthorizedBackground';
import ClientProvidersWrapper from './wrappers/ClientProviders';
import AuthorizedBackgroundMinimizedWrapper from './wrappers/AuthorisedBackground.min';
import InvitePageLoader from '../pages/common/Invite';

const ScrollToTopOnChanges = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);
  return null;
};

export default function AppRouter() {
  const { isAuthorised } = useAppState();

  return (
    <BrowserRouter>
      <ScrollToTopOnChanges />

      <Routes>
        <Route path={'/'}>
          {isAuthorised ? (
            <Route path={'/'} Component={ClientProvidersWrapper}>
              <Route path={'/'} Component={AuthorizedBackgroundWrapper}>
                <Route path="/" Component={HomePage} />
                <Route path="/marketplace" Component={MarketplacePage} />
                <Route path="/orders" Component={OrdersPage} />
                <Route path="/trades" Component={TradesPage} />
                <Route path="/profile" Component={MyProfilePage} />
                <Route path="/faq" Component={FAQPage} />
                <Route path="/tutorials" Component={TutorialsPage} />
              </Route>

              <Route path="/" Component={AuthorizedBackgroundMinimizedWrapper}>
                <Route path="/orders/:orderId" Component={OrderDetailsPage} />
                <Route path="/trades/:tradeId" Component={TradeDetailsPage} />
                <Route path="/marketplace/:orderId" Component={MarketplaceOrderDetailsPage} />
                <Route path="/user/:userId" Component={UserPage} />
              </Route>

              <Route path="/">
                <Route path="/orders/make/buy" Component={OrdersMakePage} />
                <Route path="/orders/make/sell" Component={OrdersMakePage} />
                <Route path="/orders/edit/:orderId" Component={OrdersEditPage} />
              </Route>
            </Route>
          ) : (
            <Route path={'/'} Component={BackgroundWithFormWrapper}>
              <Route path="/" Component={LoginPage} />
              <Route path="/sign-up" Component={SignUpPage} />
              <Route path="/forgot-password" Component={ForgotPasswordPage} />
            </Route>
          )}
          <Route path={'/'} Component={BackgroundWithDocumentWrapper}>
            <Route path="/privacy" Component={PrivacyPolicyPage} />
            <Route path="/terms" Component={TermsAndConditionsPage} />
            <Route path={'/invite/set-password'} Component={InvitePageLoader} />
            <Route path="*" Component={Page404} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
