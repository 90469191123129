import React, { useCallback } from 'react';
import { useGetActiveProductsAmountQuery } from 'api/queries/product/active-products-amount';
import { useModals } from 'providers/Modals';
import { useAppState } from '../providers/AppState';

export default function useCheckOrderCreationAccess() {
  const { open: openModal } = useModals();
  const { settings } = useAppState();
  const { refetch: fetchActiveOrdersCount, isRefetching, isLoading } = useGetActiveProductsAmountQuery();

  const checkingForCreationAccess = [isLoading, isRefetching].some(Boolean);

  const checkAndContinue = useCallback(
    (onSuccess: () => void) => {
      fetchActiveOrdersCount().then((amount) => {
        if (typeof amount?.data !== 'number') return;

        if (amount.data < settings.limit.active_orders) {
          onSuccess();
        } else {
          openModal('cancel-confirm', {
            title: 'Order limit reached',
            descriptionElement: (
              <p className="text-sm text-center text-brand-black">
                Sorry, you have reached the limit of <span className="font-black">5 active orders</span> at the same
                time {`(orders with statuses "Active", "Pending" and "Traded").`} To be able to create a new order,
                either one of your current active orders needs to be completed or canceled.
              </p>
            ),
            image: 'lotOfBoxes',
            cancel: {
              label: 'Got it',
            },
          });
        }
      });
    },
    [fetchActiveOrdersCount, openModal, settings],
  );

  return [checkAndContinue, checkingForCreationAccess] as const;
}
