import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';
import { createErrorHandler } from 'api/config/axios.errors';

type Params = {
  trade_id: string;
};

async function completeTrade(params: Params) {
  const result = await axiosClient.mutate.post('/api/v1/trade/complete', params);

  return result.data;
}

const mutationKey = ['trade', 'complete'] as const;

export function useCompleteTradeMutation() {
  return useMutation({
    mutationKey,
    mutationFn: completeTrade,
    onError: createErrorHandler(
      "You can't complete this trade now. If you still have the same issue, please contact us to resolve it",
    ),
  });
}
