import clsx from 'clsx';
import { useState } from 'react';
import CustomerInfo from 'components/preview/CustomerInfo';
import { useDataValues } from 'providers/DataValues';
import { useAppState } from 'providers/AppState';
import ReviewsTable from './components/ReviewsTable';
import PersonalInfo from './components/PersonalInfo';

export default function MyProfilePage() {
  const { userId } = useDataValues();
  const { logout } = useAppState();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="w-full flex flex-col flex-grow">
      <div className="flex flex-col mb-6 px-4 py-3 lg:px-10 md:py-6 text-brand-black gap-7" style={gradientStyle}>
        <div className="w-full flex flex-col justify-start md:flex-row md:justify-between">
          <div className="w-full flex flex-row justify-between items-center">
            <h4 className="font-black text-base md:text-3.5xl/normal pl-1 md:pl-4">My Profile</h4>
            <button
              onClick={() => logout()}
              className="flex md:hidden flex-row justify-center self-center rounded-[40px] items-center text-xs text-white font-semibold whitespace-nowrap ml-4 h-6 px-4 bg-brand-purple-gray"
            >
              Log out
            </button>
          </div>
          <CustomerInfo userId={userId} />
          <button
            onClick={() => logout()}
            className="hidden md:flex flex-row justify-center self-center rounded-[40px] items-center text-xs text-white font-semibold whitespace-nowrap ml-4 h-6 px-4 bg-brand-purple-gray"
          >
            Log out
          </button>
        </div>
      </div>
      <div className="flex h-9 mb-4 px-6 lg:px-10">
        <button
          className={clsx(
            'flex md:flex-none md:min-w-[120px] flex-1 items-center justify-center text-xs font-semibold text-center whitespace-nowrap p-4 h-[25px] rounded-l-md',
            activeIndex === 0 ? 'bg-brand-black text-white' : 'bg-brand-purple-card text-brand-black',
          )}
          onClick={() => setActiveIndex(0)}
        >
          <span>Personal Info</span>
        </button>
        <button
          className={clsx(
            'flex md:flex-none md:min-w-[120px] flex-1 items-center justify-center text-xs font-semibold text-center whitespace-nowrap p-4 h-[25px] rounded-r-md',
            activeIndex === 1 ? 'bg-brand-black text-white' : 'bg-brand-purple-card text-brand-black',
          )}
          onClick={() => setActiveIndex(1)}
        >
          <span>Reviews</span>
        </button>
      </div>
      {activeIndex === 1 ? <ReviewsTable userId={userId} /> : <PersonalInfo />}
    </div>
  );
}

const gradientStyle = { background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 0.01%, #E9E9F8 100%)' };
