import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

const PUSH_NOTIFICATION_FOREGROUND_EVENT = 'foreground-notification';

export default function useForegroundNotificationListener() {
  const navigate = useNavigate();
  useEffect(() => {
    const listener = (e: any) => {
      const payload = e.detail || {};

      if (payload?.smoove_ignore_inapp) {
        return;
      }

      let title = '';
      let description = '';
      let path = '';
      try {
        title = payload?.aps?.alert?.title || payload?.gcm?.title;
        description = payload?.aps?.alert?.body || payload?.gcm?.body;
        path = payload?.smoove_path;
      } catch (err) {
        console.error(err);
      }

      if (title || description) {
        enqueueSnackbar(
          <p>
            <b>{title}</b>
            <br />
            {description}
          </p>,
          {
            variant: 'default',
            className: 'whitespace-pre-wrap [&>div:first-child]:text-xs',
            autoHideDuration: 15000,
            action(notifyId: string | number) {
              return (
                <>
                  <button
                    type="button"
                    onClick={() => closeSnackbar(notifyId)}
                    className="text-xs font-semibold px-2 py-1 bg-white/30 hover:bg-white/40 rounded"
                  >
                    {path ? 'Close' : 'OK'}
                  </button>

                  {!!path && (
                    <button
                      type="button"
                      onClick={() => {
                        closeSnackbar(notifyId);
                        const { origin } = window.location;

                        location.href = origin + (path.startsWith('/') ? path : '/' + path);
                      }}
                      className="text-xs font-semibold px-2 py-1 bg-white/30 hover:bg-white/40 rounded ml-3"
                    >
                      Open
                    </button>
                  )}
                </>
              );
            },
          },
        );
      }
    };

    document.body.addEventListener(PUSH_NOTIFICATION_FOREGROUND_EVENT, listener);

    return () => {
      document.body.removeEventListener(PUSH_NOTIFICATION_FOREGROUND_EVENT, listener);
    };
  }, [navigate]);
}
