export default function BackArrowButtonIcon({ size = 26, color: _1, filled: _2, ...props }: IconProps) {
  return (
    <svg width={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="13" r="13" fill="#EBEBF4" />
      <path
        d="M8.12281 13.2395L14.5161 17.8229C15.1372 18.2682 16 17.8226 16 17.0565L16 8.94353C16 8.17744 15.1372 7.73182 14.5161 8.1771L8.12281 12.7605C7.95906 12.8779 7.95907 13.1221 8.12281 13.2395Z"
        fill="#272733"
      />
    </svg>
  );
}
