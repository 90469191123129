import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import axiosInstance from 'api/config/axios';
import { ProductPropertyTypeCode } from 'utils/enums';
import { useDataValues } from 'providers/DataValues';
import { memoryQueryClient } from 'providers/TanstackQuery';

type Params = {
  userId: string;
  attrIDs: string[];
};

async function getActiveProductsAmount({ userId, attrIDs }: Params) {
  const searchParams = new URLSearchParams();
  searchParams.set('page[limit]', '0');
  searchParams.set('filter[&&][0][&&][][==][product.status]', '1');
  searchParams.append(
    `filter[&&][0][&&][][!=][product:prop("${ProductPropertyTypeCode.Creator}",null,"${userId}")]`,
    userId,
  );

  attrIDs.filter(Boolean).forEach((attr) => {
    searchParams.append(`filter[&&][1][||][][!=][product:has("attribute","default", "${attr}")]`, attr);
  });

  const response = await axiosInstance.query.get(`/admin/default/jsonadm/product?${searchParams.toString()}`);

  return response.data;
}

export const getQueryKey = (params: Params) => ['product', params.userId, ...params.attrIDs] as const;

const selector = (result: { meta: { total: number } }) => {
  return result?.meta?.total ?? 0;
};

export function useGetActiveProductsAmountQuery(enabled = true) {
  const { appValues, profile } = useDataValues();

  const userId = (profile?.id || '').toString();
  const activeAttrId = appValues?.attrMapping.orderStatus.Active?.id || '';
  const pendingAttrId = appValues?.attrMapping.orderStatus.Pending?.id || '';
  const tradedAttrId = appValues?.attrMapping.orderStatus.Traded?.id || '';
  const editingAttrId = appValues?.attrMapping.orderStatus.Editing?.id || '';

  const [queryKey, queryFn] = useMemo(
    () =>
      [
        getQueryKey({
          userId,
          attrIDs: [activeAttrId, editingAttrId, pendingAttrId, tradedAttrId],
        }),
        () => getActiveProductsAmount({ userId, attrIDs: [activeAttrId, editingAttrId, pendingAttrId, tradedAttrId] }),
      ] as const,
    [userId, activeAttrId, pendingAttrId, tradedAttrId, editingAttrId],
  );

  return useQuery(
    {
      queryKey,
      queryFn,
      enabled: !!userId && !!activeAttrId && !!editingAttrId && enabled,
      select: selector,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      networkMode: 'offlineFirst',
      staleTime: 1000 * 60 * 1.5,
    },
    memoryQueryClient,
  );
}
