import { Link, Outlet } from 'react-router-dom';
import blackLogoSrc from 'assets/png/logo-black.png';
import { KEYS, logEvent } from 'utils/analytics';
import ModalsProvider, { useModals } from 'providers/Modals';

function BackgroundWithFormWrapperChildren() {
  const { open: openModal } = useModals();
  return (
    <div className="bg-neutral-100 min-h-screen w-full flex flex-col justify-between">
      <div className="bg-brand-black h-[45vh] w-full">
        <header className="mx-auto w-fit pb-3 pt-7 px-6">
          <Link to={'/'}>
            <img src={blackLogoSrc} className={'aspect-auto h-[100px]'} alt={'Smoove. Have it your way'} />
          </Link>
        </header>
      </div>

      <main className="flex flex-col mx-auto relative max-w-[480px] w-full">
        <div className="mt-[-22vh] bg-white min-h-[66vh] rounded-[20px] shadow-s-purple flex">
          <Outlet />
        </div>
      </main>

      <footer className={'flex justify-center flex-wrap gap-x-8 gap-y-6 p-6 text-slate-700 mt-16'}>
        <Link
          to={'/privacy'}
          onClick={() => {
            logEvent(KEYS.privacy_policy);
          }}
          className={'underline hover:text-brand-red-main cursor-pointer'}
        >
          Privacy Policy
        </Link>

        <Link
          to={'/terms'}
          onClick={() => {
            logEvent(KEYS.terms_conditions);
          }}
          className={'underline hover:text-brand-red-main cursor-pointer'}
        >
          Terms and Conditions
        </Link>

        <button
          className={'underline hover:text-brand-red-main cursor-pointer'}
          onClick={() => openModal('report-issue')}
        >
          Report an issue
        </button>
      </footer>
    </div>
  );
}

export default function BackgroundWithFormWrapper() {
  return (
    <ModalsProvider>
      <BackgroundWithFormWrapperChildren />
    </ModalsProvider>
  );
}
