export default function ProfileIcon({ size = 26, isActive }: { size?: number; isActive?: boolean }) {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="13" className={isActive ? 'fill-brand-black' : 'fill-brand-gray-soft'} />
      <path
        d="M18.2854 17.9261C18.6648 17.847 18.8908 17.4499 18.7022 17.1113C18.2866 16.3647 17.6318 15.7087 16.7941 15.2088C15.7152 14.5649 14.3933 14.2159 13.0334 14.2159C11.6735 14.2159 10.3517 14.5649 9.2728 15.2088C8.4351 15.7087 7.78027 16.3647 7.3646 17.1113C7.17605 17.4499 7.40204 17.847 7.78148 17.9261C11.2456 18.648 14.8213 18.648 18.2854 17.9261Z"
        className={isActive ? 'fill-white' : 'fill-brand-black'}
      />
      <circle cx="13.0335" cy="9.41126" r="3.43195" className={isActive ? 'fill-white' : 'fill-brand-black'} />
    </svg>
  );
}
