import * as Sentry from '@sentry/react';
import { getAppInfo } from './constants';

function getTags() {
  const appInfo = getAppInfo();
  return {
    'app.isMobileApp': (window as any).__appInfo ? 'MOBILE' : 'WEBSITE',
    'app.version': appInfo.version || 'N/A',
    'app.versionCode': appInfo.versionCode || 'N/A',
    'app.packageName': appInfo.packageName || 'N/A',
  };
}

function showCrashReport(eventId: string) {
  if (!showCrashReport.done) {
    Sentry.showReportDialog({ eventId, lang: 'en' });
    showCrashReport.done = true;
  }
}

showCrashReport.done = false;
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS || '',
  integrations: [
    Sentry.feedbackIntegration({
      autoInject: false,
    }),
  ],
  enabled: process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_ENABLED === '1',
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver', 'Pubnub', 'PubNub'],
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      // This error doesn't block the app, and it's related to 3rd-party dependencies, that are hard to debug.
      // So we ignore this issue, because fix is not clear
      if (
        ['ResizeObserver loop limit exceeded', 'ResizeObserver', 'Pubnub', 'PubNub'].every(
          (errBody) => !event.message?.includes(errBody),
        )
      ) {
        showCrashReport(event.event_id);
      }
    }
    return event;
  },
  initialScope: {
    tags: getTags(),
  },
});

export function sendIssueReport(name: string, email: string, message: string) {
  Sentry.setTags(getTags());
  Sentry.sendFeedback({ name, email, message, url: location.href }).catch(console.error);
}
