export default function BoxRoundedIcon({ size = 26, color: _1, filled: _2, ...props }: IconProps) {
  return (
    <svg width={size} height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="13" cy="13" r="13" fill="#272733" />
      <path
        d="M18.3846 8.84925L15.2308 5.61937C14.8461 5.23487 14.3077 5.00416 13.6923 5.00416H9.15385C8 4.92726 7 5.92699 7 7.08051V17.8468C7 19.0003 7.92308 20 9.15385 20H16.8462C18 20 19 19.0772 19 17.8468V10.3104C19 9.77207 18.7692 9.23376 18.3846 8.84925ZM10.6923 11.0794H13C13.3077 11.0794 13.6154 11.3101 13.6154 11.6947C13.6154 12.0791 13.3846 12.3098 13 12.3098H10.6923C10.3846 12.3098 10.0769 12.0791 10.0769 11.6947C10.0769 11.3101 10.3846 11.0794 10.6923 11.0794ZM15.3077 15.3859H10.6923C10.3846 15.3859 10.0769 15.1552 10.0769 14.7707C10.0769 14.3862 10.3077 14.1555 10.6923 14.1555H15.3077C15.6154 14.1555 15.923 14.3862 15.923 14.7707C15.923 15.1552 15.6154 15.3859 15.3077 15.3859Z"
        fill="white"
      />
    </svg>
  );
}
