import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import DashboardIcon from 'assets/svg/Dashboard';
import MarketplaceIcon from 'assets/svg/Marketplace';
import MyOrdersIcon from 'assets/svg/MyOrders';
import MyTradesIcon from 'assets/svg/MyTrades';
import { useVisibleContent } from 'providers/VisibleContent';
import TabBarItem from './components/tabBarItem/TabBarItem';
import { OnboardingManagerTooltips, OnboardingStep } from '../OnboardingManager/config';

type Props = {
  simplifyLayoutForPaths?: string[];
};

export default function TabBar({ simplifyLayoutForPaths = [] }: Props) {
  const location = useLocation();
  const isSimplifiedRoute = simplifyLayoutForPaths.includes(location.pathname);
  const { setVisibleContent } = useVisibleContent();

  return (
    <>
      {!isSimplifiedRoute && (
        <footer
          className={clsx(
            'fixed bottom-0 left-0 right-0 bg-white shadow-s-purple px-6 z-10',
            'border-t border-t-neutral-100',
            'md:hidden',
            'h-[78px] flex justify-around gap-5',
          )}
        >
          <TabBarItem
            title="Dashboard"
            route="/"
            isActive={location.pathname === '/'}
            icon={DashboardIcon}
            onClick={() => {
              setVisibleContent('main');
            }}
            onboarding={OnboardingManagerTooltips[OnboardingStep.Dashboard].mobile}
          />
          <TabBarItem
            title="Marketplace"
            route="/marketplace"
            isActive={location.pathname === '/marketplace'}
            icon={MarketplaceIcon}
            onClick={() => {
              setVisibleContent('main');
            }}
            onboarding={OnboardingManagerTooltips[OnboardingStep.Marketplace].mobile}
          />
          <TabBarItem
            title="My Orders"
            route="/orders"
            isActive={location.pathname === '/orders'}
            icon={MyOrdersIcon}
            onClick={() => {
              setVisibleContent('main');
            }}
            onboarding={OnboardingManagerTooltips[OnboardingStep.MyOrders].mobile}
          />
          <TabBarItem
            title="My Trades"
            route="/trades"
            isActive={location.pathname === '/trades'}
            icon={MyTradesIcon}
            onClick={() => {
              setVisibleContent('main');
            }}
            onboarding={OnboardingManagerTooltips[OnboardingStep.MyTrades].mobile}
          />
        </footer>
      )}
    </>
  );
}
