import React, { useState } from 'react';
import clsx from 'clsx';
import { ArrowButtonIcon } from 'assets/svg/ArrowButton';

type Tutorial = {
  tutorial: {
    id: string;
    label: string;
    texts: {
      html: string;
      css: string;
    }[];
  };
};

export const TutorialComponent = ({ tutorial }: Tutorial) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={tutorial.id} className="py-1 px-4 md:px-8">
      <div
        onClick={toggleAccordion}
        className={clsx(
          'flex justify-between items-center px-4 bg-brand-purple-card w-full h-[45px] rounded-lg cursor-pointer',
          'bg-brand-purple-card text-sm md:text-base md:font-bold font-semibold',
        )}
      >
        {tutorial.label}
        <ArrowButtonIcon className={isOpen ? 'fill-brand-purple-gray' : 'rotate-180 fill-brand-black'} />
      </div>

      {isOpen && (
        <ol className="py-4 md:p-4 max-w-[760px]">
          {tutorial.texts?.map((text, index) => (
            <li key={text.html} className="text-sm/relaxed mb-6 break-words flex gap-4">
              <div
                className={clsx(
                  'flex min-w-[18px] min-h-[18px] bg-brand-black rounded-full justify-center items-center h-fit',
                  'text-white text-xs/none font-black mt-0.5 px-1',
                )}
              >
                {index + 1}
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: text.html }}
                className={'[&_ul]:list-disc [&_ul]:list-inside [&_ol]:list-decimal [&_ol]:list-inside'}
              />
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};
