import { useMemo } from 'react';
import { useGetAllCmsQuery } from 'api/queries/cms/all-cms';
import { CMSType, GroupedCMSData } from 'api/queries/cms/types';
import LoadingIndicator from 'components/LoadingIndicator';
import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';

import { TutorialComponent } from './components/Tutorial';

export default function TutorialsPage() {
  const { data: allTutorials, isLoading: loadingCms } = useGetAllCmsQuery(CMSType.TUTORIAL);

  const tutorials = useMemo(
    () =>
      allTutorials?.reduce((acc: GroupedCMSData, curr) => {
        if (!acc[curr.section]) {
          acc[curr.section] = [];
        }
        acc[curr.section].push(curr);
        return acc;
      }, {}) || {},
    [allTutorials],
  );

  return (
    <div className="w-full flex flex-col flex-grow">
      <div className="flex flex-col mb-4 px-4 py-3 lg:px-10 lg:py-10 text-brand-black gap-7" style={gradientStyle}>
        <div className="w-full flex flex-col justify-start md:flex-row md:justify-between">
          <div className="w-full flex flex-row justify-start items-center">
            <h4 className="font-black text-base md:text-3.5xl/normal pl-1 md:pl-4">Tutorials</h4>
          </div>
        </div>
      </div>
      <div className="flex flex-col pb-2">
        {loadingCms ? (
          <div className={'flex justify-center p-3 bg-white rounded shadow-s-gray'}>
            <LoadingIndicator size={24} className={'text-brand-red-origin'} />
          </div>
        ) : (
          <>
            {Object.keys(tutorials)?.map((tutorialSection) => {
              return (
                <div key={tutorialSection}>
                  <div className="flex flex-row items-center justify-start my-2 px-4 md:px-8">
                    {!!tutorialSection && <div className="text-xs font-medium pr-4">{tutorialSection}</div>}
                    <HorizontalDashedDelimiter
                      className="flex flex-1"
                      strokeDasharray={'0.1 8'}
                      strokeLinecap="round"
                    />
                  </div>
                  {tutorials[tutorialSection].map((tutorial) => {
                    return <TutorialComponent key={tutorial.id} tutorial={tutorial} />;
                  })}
                </div>
              );
            })}

            {Object.keys(tutorials)?.length === 0 && (
              <div className={'flex justify-center p-2 bg-white rounded shadow-s-gray'}>
                <p className={'text-brand-black text-xs text-center'}>{'There is no tutorials yet.'}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const gradientStyle = { background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 0.01%, #E9E9F8 100%)' };
