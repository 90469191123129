import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/config/axios';

export async function getCsrfOptions() {
  await axiosInstance.mutate.get('/sanctum/csrf-cookie');

  return { success: true };
}

export const getCsrfQueryKey = () => ['common', 'get-csrf'] as const;

export function useGetCsrfQuery(enabled = true) {
  return useQuery({
    queryKey: getCsrfQueryKey(),
    queryFn: getCsrfOptions,
    enabled,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 1.5, // 90 seconds
    networkMode: 'offlineFirst',
  });
}
