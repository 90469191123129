import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';

export type AppSettings = {
  success: boolean;
  registration: boolean;
  limit: {
    marketplace: number;
    alerts: number;
    reference: number;
    active_orders: number;
  };
};

async function getAppSettings() {
  const response = await axiosClient.mutate.get<AppSettings>('/api/v1/settings');

  response.data.limit.marketplace = +response.data.limit.marketplace || 10;
  response.data.limit.active_orders = +response.data.limit.active_orders || 5;
  response.data.limit.alerts = +response.data.limit.alerts || 10;
  response.data.limit.reference = +response.data.limit.reference || 6;

  return response.data;
}

export const getAppSettingsQueryKey = () => ['common', 'config'] as const;

export const initialDataSettings: AppSettings = {
  success: true,
  registration: false,
  limit: {
    marketplace: 10,
    alerts: 10,
    reference: 6,
    active_orders: 5,
  },
};

export function useGetAppSettingsQuery(enabled = true) {
  return useQuery({
    queryKey: getAppSettingsQueryKey(),
    queryFn: getAppSettings,
    enabled: enabled,
    refetchOnWindowFocus: true,
  });
}
