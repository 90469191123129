import clsx from 'clsx';
import { lazy, Suspense } from 'react';
import CloseRoundedIcon from 'assets/svg/CloseRounded';
import { useChatModal } from 'providers/ChatModalProvider';
import IndicatorPageLoader from 'navigation/loaders/Indicator';

const ChatContent = lazy(() => import('./content'));

export default function ChatModal() {
  const { closeChat, chatClient, isLoading } = useChatModal();

  return (
    <div
      className={clsx(
        'fixed bottom-0 left-0 right-0 top-0 z-40 bg-brand-blue-dark/40 min-w-[328px]',
        'flex flex-col justify-center items-center',
      )}
      onClick={closeChat}
    >
      <section className={clsx('block w-full max-w-[1024px] relative', 'md:p-0')} onClick={(e) => e.stopPropagation()}>
        <div
          className={clsx(
            'block relative max-h-[95vh] bg-white',
            'rounded-2xl border border-brand-purple-border shadow-s-purple-intensive',
            'p-1',
          )}
        >
          <Suspense fallback={<IndicatorPageLoader fullPage />}>
            {!isLoading ? <ChatContent chatClient={chatClient} /> : <IndicatorPageLoader fullPage />}
          </Suspense>
        </div>

        <button className={'absolute -top-2 right-0 lg:-right-2 hover:opacity-90 self-end z-[2]'} onClick={closeChat}>
          <CloseRoundedIcon color="currentColor" className="h-6 w-6 text-brand-black" />
        </button>
      </section>
    </div>
  );
}
