import { HTMLProps } from 'react';
import clsx from 'clsx';
import { OrderStatus } from '../utils/enums';

type Props = Pick<HTMLProps<HTMLDivElement>, 'className' | 'style'> & {
  status: OrderStatus;
};

const classByStatus: Record<OrderStatus, string> = {
  [OrderStatus.Active]: clsx('bg-[#FFA800]/10 text-[#FF6B00]'),
  [OrderStatus.Editing]: clsx('bg-[#FFA800]/10 text-[#FF6B00]'),
  [OrderStatus.Completed]: clsx('bg-[#ECFBEF] text-[#7DDCA3]'),
  [OrderStatus.Pending]: clsx('bg-[#EAEAF9] text-[#8C8CAA]'),
  [OrderStatus.Replaced]: clsx('text-[#CACAE4]'),
  [OrderStatus.Traded]: clsx('bg-[#D6F0C6] text-[#4BAA00]'),
  [OrderStatus.Canceled]: clsx('bg-[#E94169]/10 text-[#E94169]'),
  [OrderStatus.Declined]: clsx('bg-[#E94169]/10 text-[#E94169]'),
};

export const mainColorByStatus: Record<OrderStatus, string> = {
  [OrderStatus.Active]: '#FF6B00',
  [OrderStatus.Editing]: '#FF6B00',
  [OrderStatus.Completed]: '#7DDCA3',
  [OrderStatus.Pending]: '#8C8CAA',
  [OrderStatus.Replaced]: '#CACAE4',
  [OrderStatus.Traded]: '#4BAA00',
  [OrderStatus.Canceled]: '#E94169',
  [OrderStatus.Declined]: '#E94169',
};

export const labelByStatus: Record<OrderStatus, string> = {
  [OrderStatus.Active]: 'Active',
  [OrderStatus.Editing]: 'Editing',
  [OrderStatus.Completed]: 'Completed',
  [OrderStatus.Pending]: 'Pending',
  [OrderStatus.Replaced]: 'Replaced',
  [OrderStatus.Traded]: 'Traded',
  [OrderStatus.Canceled]: 'Canceled',
  [OrderStatus.Declined]: 'Declined',
};

export default function StatusBadge({ status, className, style }: Props) {
  const _class = classByStatus[status];

  if (!_class) {
    return <p></p>;
  }

  return (
    <p
      className={clsx('px-3 py-1.5 rounded-[20px] text-xs font-semibold text-center align-middle', _class, className)}
      style={style}
    >
      {labelByStatus[status]}
    </p>
  );
}
