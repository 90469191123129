import Pubnub, { MessageEvent, ObjectsEvent } from 'pubnub';
import { Dispatch, useEffect } from 'react';
import { ChatReducerAction } from '../reducer/types';

export default function useChatListeners(dispatch: Dispatch<ChatReducerAction>, chatClient?: Pubnub) {
  useEffect(() => {
    if (chatClient) {
      const message = (messageEvent: MessageEvent) => {
        dispatch({ type: 'MESSAGE_EVENT', payload: messageEvent });
      };

      const objects = (objectEvent: ObjectsEvent) => {
        dispatch({ type: 'OBJECTS_EVENT', payload: objectEvent });
      };

      const listeners = { message, objects };

      chatClient.addListener(listeners);

      return () => chatClient.removeListener(listeners);
    }
  }, [chatClient, dispatch]);
}
