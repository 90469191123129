import clsx from 'clsx';
import RatingStar from 'assets/svg/RatingStar';

type Props = {
  rating: number;
};

export default function ReviewStars({ rating }: Props) {
  const stars = [
    rating >= 0.8 ? 1 : rating >= 0.3 && rating < 0.8 ? 0.5 : 0,
    rating >= 1.8 ? 1 : rating >= 1.3 && rating < 1.8 ? 0.5 : 0,
    rating >= 2.8 ? 1 : rating >= 2.3 && rating < 2.8 ? 0.5 : 0,
    rating >= 3.8 ? 1 : rating >= 3.3 && rating < 3.8 ? 0.5 : 0,
    rating >= 4.8 ? 1 : rating >= 4.3 && rating < 4.8 ? 0.5 : 0,
  ];

  return (
    <div className="flex flex-row gap-2">
      {stars.map((starRate, i) => {
        const fullStar = starRate === 1;
        const halfStar = fullStar ? false : starRate === 0.5;

        return (
          <RatingStar
            key={i}
            className={clsx(
              'min-w-4 max-w-4 w-4',
              fullStar || halfStar ? 'text-brand-red-main' : 'text-brand-red-main/10',
            )}
            halfStar={halfStar}
          />
        );
      })}
    </div>
  );
}
