import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { OrderStatus } from 'utils/enums';
import { useIsTabletAndLess } from 'hooks/media-query';
import { HorizontalDashedDelimiter } from 'components/DashedDelimiter';
import { useModals } from 'providers/Modals';
import { ParsedOrder } from 'api/order.parsers';
import useOrderCanLeaveReview from 'hooks/useOrderCheckLeaveReview';
import { AimeosProductType } from 'api/types';
import { useCancelTradeMutation } from 'api/mutations/trades/cancel';
import { memoryQueryClient } from 'providers/TanstackQuery';
import { getOrderBaseQueryKey } from 'api/queries/product/get-order';
import { useCompleteTradeMutation } from 'api/mutations/trades/complete';
import { useChatModal } from 'providers/ChatModalProvider';

export default function Actions({ trade, order }: { order?: ParsedOrder; trade?: ParsedOrder }) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { mutateAsync: completeTrade } = useCompleteTradeMutation();

  const isTabletAndLess = useIsTabletAndLess();
  const buttonSize = isTabletAndLess ? 'sm' : 'lg';

  const orderStatus = trade?.tradeStatus || OrderStatus.Canceled;

  const { open: openModal } = useModals();
  const { openChat } = useChatModal();

  const showRateButton = useOrderCanLeaveReview(AimeosProductType.Trade, order, trade);

  const leaveReview = () => {
    if (trade && order) {
      openModal('leave-review', {
        orderId: order.id || '',
        orderOwnerId: order.owner || '',
        orderCode: order.code || '',
        orderType: order.orderType,
      });
    }
  };

  const [searchParams] = useSearchParams();
  const showReviewModal = showRateButton && searchParams.get('action') === 'leave-review';
  const showChatModal =
    (showRateButton || trade?.tradeStatus === OrderStatus.Traded) && searchParams.get('action') === 'open-chat';

  useEffect(() => {
    if (showReviewModal) {
      leaveReview();
    } else if (showChatModal) {
      openChat(trade?.id);
    }
  }, [showReviewModal, showChatModal]);

  const { mutateAsync: cancelTradeMutation } = useCancelTradeMutation();
  const cancelTrade = () => {
    openModal('prompt-confirm', {
      title: (
        <>
          {'Are you sure you want to'}
          <br />
          <b>Cancel Trade?</b>
        </>
      ),
      confirmLabel: 'Cancel Trade',
      cancelLabel: 'Back',
      confirm: {
        asyncOnClick: async (reason, closeModal) => {
          await cancelTradeMutation({ cancel_reason: reason, trade_id: trade?.id || '' });
          closeModal();
          memoryQueryClient.refetchQueries({
            queryKey: getOrderBaseQueryKey,
          });
          leaveReview();
        },
      },
    });
  };

  const onCompleteTrade = () => {
    openModal('cancel-confirm', {
      cancel: {
        label: 'Back',
      },
      titleElement: (
        <>
          {'Are you sure you want to '}
          <b>Complete Trade?</b>
        </>
      ),
      image: 'manAndComputer',
      confirm: {
        label: 'Complete Trade',
        asyncOnClick: async (closeModal) => {
          await completeTrade({
            trade_id: trade?.id || '',
          });
          closeModal();
          memoryQueryClient.refetchQueries({
            queryKey: getOrderBaseQueryKey,
          });
          leaveReview();
        },
      },
    });
  };

  const reportProblem = () => {
    openModal('report-problem', { tradeId: trade?.id });
  };

  const RateButton = showRateButton ? (
    <Button
      size={buttonSize}
      pill
      color="dark"
      className="min-w-[92px] font-semibold border-0 whitespace-nowrap break-words"
      onClick={leaveReview}
    >
      Rate the other party
    </Button>
  ) : null;

  const CancelTradeButton = (
    <Button
      size={buttonSize}
      pill
      color="dark"
      className="min-w-[92px] font-semibold border-0 whitespace-nowrap break-words"
      onClick={cancelTrade}
    >
      Cancel Trade
    </Button>
  );

  const ReportAProblemButton = (
    <Button
      size={buttonSize}
      pill
      color="dark"
      className="min-w-[92px] font-semibold border-0 whitespace-nowrap break-words"
      onClick={reportProblem}
    >
      Report a problem
    </Button>
  );

  const ChatButton = (
    <Button
      size={buttonSize}
      pill
      color="dark"
      className="min-w-[92px] font-semibold border-0 whitespace-nowrap break-words"
      onClick={() => openChat(trade?.id || '')}
    >
      Chat
    </Button>
  );

  const GoBackButton = (
    <Button
      size={buttonSize}
      pill
      color="light"
      className="min-w-[92px] font-semibold border-0 bg-brand-purple-light"
      onClick={goBack}
    >
      Back
    </Button>
  );

  const CompleteTradeButton = (
    <Button
      size={buttonSize}
      pill
      color="dark"
      className="min-w-[92px] bg-brand-red-main font-semibold whitespace-nowrap break-words"
      onClick={onCompleteTrade}
    >
      Complete Trade
    </Button>
  );

  if (orderStatus === OrderStatus.Canceled) {
    return (
      <div className={'flex items-center gap-2 lg:gap-4 flex-wrap'}>
        {GoBackButton}
        {trade?.canBeReviewed && ChatButton}
        <div className={'ml-auto'} />
        {RateButton}
      </div>
    );
  }

  if (orderStatus === OrderStatus.Traded) {
    if (isTabletAndLess) {
      return (
        <>
          <div className={'flex items-center gap-2 lg:gap-4'}>
            {GoBackButton}
            <div className={'ml-auto'} />
            {CompleteTradeButton}
          </div>

          <HorizontalDashedDelimiter className={'h-1 w-full my-6'} strokeLinecap={'round'} strokeDasharray={'0.1 5'} />

          <div className={'grid grid-cols-2 sm:grid-cols-3 gap-2 lg:gap-4 flex-wrap'}>
            {CancelTradeButton}
            {ReportAProblemButton}
            {ChatButton}
          </div>
        </>
      );
    }
    return (
      <div className={'flex items-center gap-2 lg:gap-4 flex-wrap'}>
        {GoBackButton}
        {CancelTradeButton}
        {ReportAProblemButton}
        {ChatButton}
        <div className={'ml-auto'} />
        {CompleteTradeButton}
      </div>
    );
  }

  if (orderStatus === OrderStatus.Completed) {
    return (
      <div className={'flex items-center gap-2 lg:gap-4 flex-wrap'}>
        {GoBackButton}
        {ChatButton}
        <div className={'ml-auto'} />
        {RateButton}
      </div>
    );
  }

  return (
    <div className={'flex items-center'}>
      {GoBackButton}
      <div className={'ml-auto'} />
    </div>
  );
}
