import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import axiosInstance from 'api/config/axios';
import { AimeosManyResponse, AimeosReview } from 'api/types';
import { useDataValues } from 'providers/DataValues';

type Params = {
  customerId: string;
  productCustomerId: string;
  productId: string;
};

async function isReviewExisted({ customerId, productCustomerId, productId }: Params) {
  const params = {
    page: {
      limit: 1,
      offset: 0,
    },
    filter: {
      f_customer: customerId,
      f_prod_customer: productCustomerId,
      f_refid: productId,
    },
  };

  const response = await axiosInstance.query.get<AimeosManyResponse<AimeosReview>>('/jsonapi/review', {
    params,
  });

  return response.data.meta.total > 0;
}

export const checkIsReviewExistedQueryKey = (params: Params) =>
  ['reviews', 'get-one', params.productId, params.productCustomerId, params.customerId] as const;

export function useIsReviewExistedQuery(params: Omit<Params, 'customerId'>, enabled = true) {
  const { userId: customerId } = useDataValues();
  const [queryKey, queryFn] = useMemo(
    () =>
      [
        checkIsReviewExistedQueryKey({ ...params, customerId }),
        () => isReviewExisted({ ...params, customerId }),
      ] as const,
    [params, customerId],
  );

  return useQuery({
    queryKey,
    queryFn,
    enabled: enabled,
    staleTime: 0,
  });
}
