import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import IndicatorPageLoader from 'navigation/loaders/Indicator';
import { useAppState } from 'providers/AppState';
import { confirmSetPasswordRequest } from 'api/mutations/auth/confirm.set-password';
import { createErrorHandler } from 'api/config/axios.errors';

export default function InvitePageLoader() {
  const [params] = useSearchParams();

  const encodedData = params.get('signature');

  const navigate = useNavigate();

  const { isAuthorised } = useAppState();

  useEffect(() => {
    if (encodedData) {
      try {
        const data = JSON.parse(atob(encodedData));
        if (data.email && data.signature) {
          if (!isAuthorised) {
            confirmSetPasswordRequest({ signature: encodedData })
              .then(() => {
                const searchParams = new URLSearchParams();
                searchParams.set('email', data.email);
                searchParams.set('signature', encodedData);
                navigate(`/sign-up?${searchParams.toString()}`, { replace: true });
              })
              .catch((e) => {
                createErrorHandler("You've already set your password")(e);
                navigate('/', { replace: true });
              });
            return;
          }
        }
      } catch (err) {
        console.error(err);
        navigate('/', { replace: true });
      }
    }

    navigate('/', { replace: true });
  }, [encodedData]);

  return <IndicatorPageLoader />;
}
