import { PropsWithChildren } from 'react';
import LoadingIndicator from '../LoadingIndicator';

type Props = PropsWithChildren<{
  label: string;
  tip?: string;
  error?: string;
  loading?: boolean;
  headerClass?: string;
}>;

export default function FormFieldBlock({ children, label, tip, error, loading, headerClass }: Props) {
  return (
    <label className={'flex flex-col gap-1.5'}>
      <p className={headerClass || 'text-sm text-brand-black'}>
        {label} {loading && <LoadingIndicator size={14} className={'inline my-auto ml-1'} />}
      </p>

      {children}

      {!!tip && <p className={'text-sm text-brand-black'}>{tip}</p>}

      {!!error && <p className={'text-sm text-red-500'}>{error}</p>}
    </label>
  );
}
