import axiosClient from '../../config/axios';

export async function finishOnboarding() {
  return axiosClient.mutate.post<unknown>(
    '/api/v1/user/onboard',
    {},
    {
      timeout: 15000,
    },
  );
}
