export default function MyTradesIcon({ size = 28, isActive }: { size?: number; isActive?: boolean }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" className={isActive ? 'fill-brand-red-main' : 'fill-brand-gray-soft'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.40876V21.3961C20 21.6199 19.8944 21.8252 19.7257 21.9299C19.557 22.0345 19.3534 22.0208 19.1957 21.8944L17.4848 20.5235L15.8672 21.8864C15.6731 22.0506 15.4085 22.0353 15.2288 21.8502L14 20.5887L12.7712 21.8502C12.5915 22.0353 12.3269 22.0506 12.1328 21.8864L10.5152 20.5235L8.80434 21.8944C8.64663 22.0208 8.44297 22.0345 8.27429 21.9299C8.1056 21.8252 8 21.6199 8 21.3961V8.40876C8 7.63112 8.53691 7 9.2 7H18.8C19.4631 7 20 7.63112 20 8.40876Z"
        className={isActive ? 'fill-white' : 'fill-brand-purple-gray'}
      />
    </svg>
  );
}
