import ChatIcon from 'assets/svg/Chat';
import { useChatModal } from 'providers/ChatModalProvider';

export default function ChatButton() {
  const {
    openChat,
    state: { hasUnreadMessages },
  } = useChatModal();
  return (
    <button onClick={() => openChat(undefined)} className={'relative'}>
      <ChatIcon
        size={32}
        className={'min-h-full min-w-full max-h-[32px] max-w-[32px] md:max-w-[26px] md:max-h-[26px]'}
      />
      {hasUnreadMessages && <div className={'absolute top-0 right-0 h-2 w-2 rounded-full bg-brand-red-main'} />}
    </button>
  );
}
