import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'api/config/axios';
import { AimeosEntityType, AimeosManyResponse, AimeosReview } from 'api/types';
import { parseManyResponse } from 'api/parsers';
import { memoryQueryClient } from 'providers/TanstackQuery';
import { ReviewsParams } from './types';

const PAGE_AMOUNT = 10;

async function getReviews({ customer, product_customer, refId, page = 0 }: ReviewsParams) {
  const params = {
    page: {
      limit: Math.max((page + 1) * PAGE_AMOUNT, PAGE_AMOUNT),
      offset: Math.max(page * PAGE_AMOUNT, 0),
    },
    filter: {
      ...(customer ? { f_customer: customer } : {}),
      ...(product_customer ? { f_prod_customer: product_customer } : {}),
      ...(refId ? { f_refid: refId } : {}),
    },
  };

  const response = await axiosInstance.query.get<AimeosManyResponse<AimeosReview>>('/jsonapi/review', {
    params,
  });
  return response.data;
}

export type ParsedReviews = ReturnType<typeof selector>['items'];

export const getReviewsQueryKey = (params: ReviewsParams) =>
  ['reviews', params.customer, params.product_customer, params.refId, params.page] as const;

function getNextPageParam(lastPage: AimeosManyResponse<AimeosReview>, pages: AimeosManyResponse<AimeosReview>[]) {
  const total = lastPage.meta.total;

  const lastPageCount = pages.length;

  if (lastPageCount * PAGE_AMOUNT < total) {
    return lastPageCount;
  }
  return undefined;
}

function selector(result: InfiniteData<Awaited<ReturnType<typeof getReviews>>>) {
  const items = result.pages.flatMap((v) => parseManyResponse<AimeosEntityType.Review>(v));
  return {
    ...result,
    items,
  };
}

export function useGetReviewsInfinityQuery(params: ReviewsParams, enabled = true) {
  return useInfiniteQuery(
    {
      queryKey: getReviewsQueryKey(params),
      queryFn: ({ pageParam = 0 }) => getReviews({ ...params, page: pageParam }),
      enabled,
      select: selector,
      initialPageParam: 0,
      getNextPageParam,
    },
    memoryQueryClient,
  );
}
