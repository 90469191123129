import React, { useCallback, useId, useState } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { useIsTabletAndLess } from 'hooks/media-query';
import { useVisibleContent } from 'providers/VisibleContent';
import CloseRoundedIcon from 'assets/svg/CloseRounded';
import HamburgerIcon from 'assets/svg/Hamburger';
import HamburgerMenu from '../../topBarMenuModals/hamburgerMenu/HamburgerMenu';

export default function MenuButton() {
  const tooltipId = useId();
  const [openedTooltip, setOpenedTooltip] = useState(false);

  const { visibleContent, setVisibleContent } = useVisibleContent();
  const isTabletAndLess = useIsTabletAndLess();

  const toggleVisibleContent = useCallback(() => {
    setVisibleContent((prev) => {
      if (prev === 'menu') {
        return 'main';
      } else {
        return 'menu';
      }
    });
    setOpenedTooltip((prev) => !prev);
  }, [setVisibleContent]);

  const active = isTabletAndLess ? visibleContent === 'menu' : openedTooltip;

  return (
    <>
      <button className="header-button relative" onClick={toggleVisibleContent} data-tooltip-id={tooltipId}>
        {active ? (
          <CloseRoundedIcon className="text-brand-black h-[32px] w-[32px] md:h-[28px] md:w-[28px] touch-none" />
        ) : (
          <HamburgerIcon className="text-brand-gray-soft h-[32px] w-[32px] md:h-[28px] md:w-[28px] touch-none" />
        )}
      </button>

      {!isTabletAndLess &&
        ReactDOM.createPortal(
          <Tooltip
            id={tooltipId}
            clickable
            openOnClick={!active}
            delayHide={1000}
            style={{ padding: 0 }}
            className={'shadow-lg rounded-lg border border-brand-purple-light mt-2'}
            variant={'light'}
            place="bottom-end"
            opacity={1}
            isOpen={active}
            setIsOpen={setOpenedTooltip}
          >
            <div className={'max-w-[366px] max-h-[40vh] p-4 w-full overflow-y-auto overflow-x-hidden'}>
              <HamburgerMenu onClick={toggleVisibleContent} />
            </div>
          </Tooltip>,
          document.body,
        )}
    </>
  );
}
