import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/config/axios';
import { GetMyProfileResponse } from './me.types';

async function getMyProfile() {
  const response = await axiosClient.mutate.get<GetMyProfileResponse>('/api/me');

  return response.data;
}

export const getMyProfileQueryKey = () => ['customer', 'my-profile'] as const;

export function useGetMyProfileQuery(enabled = true) {
  return useQuery({
    queryKey: getMyProfileQueryKey(),
    queryFn: getMyProfile,
    enabled: enabled,
    refetchOnWindowFocus: true,
  });
}
