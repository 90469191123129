export enum OrderType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum OrderUnitsType {
  Weight = 'Weight',
  Pieces = 'Pieces',
}

export enum OrderDeliveryType {
  // for Sell order
  NeedPickup = 'NeedPickup',
  CanDeliver = 'CanDeliver',
  // for Buy order
  NeedDelivery = 'NeedDelivery',
  CanPickup = 'CanPickup',
}

export enum OrderStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Traded = 'Traded',
  Pending = 'Pending',
  Replaced = 'Replaced',
  Completed = 'Completed',
  Editing = 'Editing',
  Declined = 'Declined',
}

export enum ProductPropertyTypeCode {
  Product = 'exchange-product-property-order-product',
  Variety = 'exchange-product-property-order-variety',
  Creator = 'exchange-product-property-order-creator',
  Buyer = 'exchange-product-property-order-buyer',
  TradeCreator = 'exchange-product-property-trade-creator',
}

export enum CommonAttributeTypeCode {
  Province = 'exchange-attribute-type-order-province',
  Units = 'exchange-attribute-type-order-units',
  Delivery = 'exchange-attribute-type-order-delivery',
  OrderType = 'exchange-attribute-type-order-type',
  OrderStatus = 'exchange-attribute-type-order-status',
  TradeStatus = 'exchange-attribute-type-trade-status',
}

export enum UnitAttributeCode {
  Weight = 'exchange-attribute-units-weight',
  Pieces = 'exchange-attribute-units-pieces',
}

function reverseMapping(mapping: any) {
  return Object.entries(mapping).reduce<Record<string, string>>((dict, [key, value]) => {
    dict[value as string] = key;
    return dict;
  }, {} as any);
}

export const unitsTypeToAttributeMapping: Record<OrderUnitsType, UnitAttributeCode> = {
  [OrderUnitsType.Weight]: UnitAttributeCode.Weight,
  [OrderUnitsType.Pieces]: UnitAttributeCode.Pieces,
};

export const attributeCodeToUnitTypeMapping = reverseMapping(unitsTypeToAttributeMapping) as Record<
  UnitAttributeCode,
  OrderUnitsType
>;

export enum DeliveryAttributeCode {
  To = 'exchange-attribute-delivery-to',
  From = 'exchange-attribute-delivery-from',
}

export const attributeCodeToDeliveryTypeMapping: Record<OrderType, Record<DeliveryAttributeCode, OrderDeliveryType>> = {
  [OrderType.Buy]: {
    [DeliveryAttributeCode.From]: OrderDeliveryType.NeedDelivery,
    [DeliveryAttributeCode.To]: OrderDeliveryType.CanPickup,
  },
  [OrderType.Sell]: {
    [DeliveryAttributeCode.From]: OrderDeliveryType.CanDeliver,
    [DeliveryAttributeCode.To]: OrderDeliveryType.NeedPickup,
  },
};

export enum OrderTypeAttributeCode {
  Buy = 'exchange-attribute-order-buy',
  Sell = 'exchange-attribute-order-sell',
}

export enum OrderStatusAttributeCode {
  Active = 'exchange-attribute-order-status-active',
  Canceled = 'exchange-attribute-order-status-canceled',
  Traded = 'exchange-attribute-order-status-traded',
  Pending = 'exchange-attribute-order-status-pending',
  Replaced = 'exchange-attribute-order-status-replaced',
  Completed = 'exchange-attribute-order-status-completed',
  Editing = 'exchange-attribute-order-status-editing',
}

export enum TradeStatusAttributeCode {
  Declined = 'exchange-attribute-trade-status-declined',
  Canceled = 'exchange-attribute-trade-status-canceled',
  Traded = 'exchange-attribute-trade-status-traded',
  Pending = 'exchange-attribute-trade-status-pending',
  Replaced = 'exchange-attribute-trade-status-replaced',
  Completed = 'exchange-attribute-trade-status-completed',
  Editing = 'exchange-attribute-trade-status-editing',
}

export const attributeCodeToOrderStatusMapping: Record<OrderStatusAttributeCode, OrderStatus> = {
  [OrderStatusAttributeCode.Active]: OrderStatus.Active,
  [OrderStatusAttributeCode.Canceled]: OrderStatus.Canceled,
  [OrderStatusAttributeCode.Traded]: OrderStatus.Traded,
  [OrderStatusAttributeCode.Pending]: OrderStatus.Pending,
  [OrderStatusAttributeCode.Replaced]: OrderStatus.Replaced,
  [OrderStatusAttributeCode.Completed]: OrderStatus.Completed,
  [OrderStatusAttributeCode.Editing]: OrderStatus.Editing,
};

export const tradeStatusAttributeCodeToOrderStatusMapping: Record<TradeStatusAttributeCode, OrderStatus> = {
  [TradeStatusAttributeCode.Declined]: OrderStatus.Declined,
  [TradeStatusAttributeCode.Canceled]: OrderStatus.Canceled,
  [TradeStatusAttributeCode.Traded]: OrderStatus.Traded,
  [TradeStatusAttributeCode.Pending]: OrderStatus.Pending,
  [TradeStatusAttributeCode.Replaced]: OrderStatus.Replaced,
  [TradeStatusAttributeCode.Completed]: OrderStatus.Completed,
  [TradeStatusAttributeCode.Editing]: OrderStatus.Editing,
};

export const orderTypeLabel: Record<OrderType, string> = {
  [OrderType.Buy]: 'Buy',
  [OrderType.Sell]: 'Sell',
};

export const orderUnitTypeLabel = {
  [OrderUnitsType.Weight]: 'Weight',
  [OrderUnitsType.Pieces]: 'Pieces',
};

export const orderDeliveryTypesByOrderType: Record<OrderType, DataItem<OrderDeliveryType>[]> = {
  [OrderType.Sell]: [
    { id: OrderDeliveryType.NeedPickup, label: 'I need pick-up', value: OrderDeliveryType.NeedPickup },
    { id: OrderDeliveryType.CanDeliver, label: 'I can deliver', value: OrderDeliveryType.CanDeliver },
  ],
  [OrderType.Buy]: [
    { id: OrderDeliveryType.NeedDelivery, label: 'I need delivery', value: OrderDeliveryType.NeedDelivery },
    { id: OrderDeliveryType.CanPickup, label: 'I can pick-up', value: OrderDeliveryType.CanPickup },
  ],
};

export const publicDeliveryTypeLabels = {
  [OrderDeliveryType.NeedDelivery]: 'Delivery',
  [OrderDeliveryType.NeedPickup]: 'Pick-up',
  [OrderDeliveryType.CanDeliver]: 'Delivery',
  [OrderDeliveryType.CanPickup]: 'Pick-up',
};

export const publicDeliveryTypeFullLabels = {
  [OrderDeliveryType.NeedDelivery]: 'I need delivery',
  [OrderDeliveryType.NeedPickup]: 'I need pick-up',
  [OrderDeliveryType.CanDeliver]: 'I can deliver',
  [OrderDeliveryType.CanPickup]: 'I can pick-up',
};

export const publicDeliveryTypeTraderFullLabels = {
  [OrderDeliveryType.NeedDelivery]: 'Buyer requires delivery to',
  [OrderDeliveryType.NeedPickup]: 'Seller requires pickup from',
  [OrderDeliveryType.CanDeliver]: 'Seller can deliver to',
  [OrderDeliveryType.CanPickup]: 'Buyer can pickup from',
};
