import { useMediaQuery } from 'react-responsive';

export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const useIsMobile = () => useMediaQuery({ maxWidth: breakpoints.sm });

export const useIsTablet = () => useMediaQuery({ minWidth: breakpoints.sm, maxWidth: breakpoints.lg });
export const useIsTabletAndMore = () => useMediaQuery({ minWidth: breakpoints.sm });
export const useIsTabletAndLess = () => useMediaQuery({ maxWidth: breakpoints.md });

export const useIsMedTabletAndMore = () => useMediaQuery({ minWidth: breakpoints.md });

export const useIsLargeTabletAndLess = () => useMediaQuery({ maxWidth: breakpoints.lg });

export const useIsDesktop = () => useMediaQuery({ minWidth: breakpoints.lg, maxWidth: breakpoints.xl });
export const useIsDesktopAndMore = () => useMediaQuery({ minWidth: breakpoints.lg });
export const useIsDesktopAndLess = () => useMediaQuery({ maxWidth: breakpoints.xl });

export const useIsLargeDesktop = () => useMediaQuery({ minWidth: breakpoints.xl });

export const useMediaQueries = () => {
  const isMobile = useIsMobile();
  //
  const isTablet = useIsTablet();
  const isTabletAndMore = useIsTabletAndMore();
  const isTabletAndLess = useIsTabletAndLess();
  //
  const isDesktop = useIsDesktop();
  const isDesktopAndMore = useIsDesktopAndMore();
  const isDesktopAndLess = useIsDesktopAndLess();

  const isLargeDesktop = useIsLargeDesktop();

  return {
    isMobile,
    isTablet,
    isTabletAndMore,
    isTabletAndLess,
    isDesktop,
    isDesktopAndMore,
    isDesktopAndLess,
    isLargeDesktop,
  };
};
